import React from 'react'
import { useHistory } from 'react-router-dom'

import CourseImage from '@components/course_image'

const CourseCard = ({ course }) => {
    const history = useHistory()
    const progress = course.examinee_modules ? ((course.examinee_modules.filter(examModule => examModule.module).length) / course.modules.length) * 100 : 0
    const modulesCount = course.modules.length

    return (
        <div
            className="course-card mb-20 p-20 flex"
            role="button"
            onClick={ () => history.push(`/courses/${ course.id }`) }
        >
            <div>
                <CourseImage course={ course } className="course-image" />
            </div>
            <div className="txt-white ml-20 flex direction-column flex-1">
                <div className="flex-1">
                    <div className="txt-xxxlarge txt-bold">
                        { course.name }
                    </div>
                    { course.description ? <div className="mt-10 txt-large" dangerouslySetInnerHTML={{ __html: course.short_description }} /> : null }
                    <div className="txt-medium txt-bold-semi mt-15">
                        THIS COURSE INCLUDES:
                    </div>
                    <div className="txt-medium-large txt-bold mt-10 flex align-center">
                        <i className="fas fa-circle" style={{ fontSize: 9, width: 20 }} /> { modulesCount } module{ modulesCount > 1 ? 's' : '' } ({ course.getTotalDuration() } Total of videos)
                    </div>
                    <div className="txt-medium-large txt-bold mt-10 flex align-center">
                        <i className="fas fa-circle" style={{ fontSize: 9, width: 20 }} /> { course.asset_groups && course.asset_groups.length ? course.asset_groups[0].assets.length : 0 } Downloadable Resources
                    </div>
                </div>
                {
                    course.examinee_done_session ? (
                        <div className="flex flex-end align-center txt-error">
                            <i className="fas fa-trophy mr-10" /> 100% Completed
                        </div>
                    ) : (
                        course.examinee_modules && course.examinee_modules.length ? (
                            <div className="flex align-center mt-20">
                                <div className="course-progress-bar flex-1 position-relative">
                                    <div
                                        className="position-absolute"
                                        style={{
                                            maxWidth: `${ progress }%`
                                        }}
                                    />
                                </div>
                                <div className="course-progress-count txt-bold txt-right">
                                    { progress.toFixed(2) / 1 }% In-progress
                                </div>
                            </div>
                        ) : null
                    )
                }
            </div>
        </div>
    )
}

export { CourseCard }