import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { TaskCard } from './components'

const DashboardTasks = ({ courses }) => {
    const sortedOngoingCourses = [...courses.on_going].sort((a, b) => {
            if ((a.examinee_modules[a.examinee_modules.length - 1]).updated_at > (b.examinee_modules[b.examinee_modules.length - 1]).updated_at){
                return -1;
            }
            if((a.examinee_modules[a.examinee_modules.length - 1]).updated_at < (b.examinee_modules[b.examinee_modules.length - 1]).updated_at){
                return 1;
            }
            return 0;
      });
    const sortedPendingCourses = [...courses.pending].sort((a, b) => {
        return a.order > b.order;
      });

    const [ongoingCourses, setOngoingCourses] = useState(sortedOngoingCourses);
    const [pendingCourses, setPendingCourses] = useState(sortedPendingCourses);

    useEffect(() => {
        const sortedOngoingCoursesRe = [...courses.on_going].sort((a, b) => {
            if ((a.examinee_modules[a.examinee_modules.length - 1]).updated_at > (b.examinee_modules[b.examinee_modules.length - 1]).updated_at){
                return -1;
            }
            if((a.examinee_modules[a.examinee_modules.length - 1]).updated_at < (b.examinee_modules[b.examinee_modules.length - 1]).updated_at){
                return 1;
            }
            return 0;
      });
        const sortedPendingCoursesRe =[...courses.pending].sort((a, b) => {
            return a.order > b.order;
        });

        setOngoingCourses(sortedOngoingCoursesRe)
        setPendingCourses(sortedPendingCoursesRe)
    }, [courses])

    return (
        <div className="dashboard-tasks mt-40">
            <div className="flex align-center space-between">
                <div className="txt-black txt-large">
                    COURSES
                </div>
                <div>
                    <Link
                        to="/courses"
                        className="btn-clear btn-arrow flex align-center txt-error"
                    >
                        <span className="txt-large txt-secondary-bold">
                            See all
                        </span>
                        <i className="far fa-arrow-right ml-10 arrow"/>
                    </Link>
                </div>
            </div>
            
            {/* MAXIMUM TWO DISPLAY */}
            { ongoingCourses.length == 2 && (
                ongoingCourses.map((task) => <TaskCard task={ task } key={`dashboardtask${ task.id }`} withProgress /> )
                )
            }
            { ongoingCourses.length > 2 && (
                ongoingCourses.splice(0, 2).map((task) => <TaskCard task={ task } key={`dashboardtask${ task.id }`} withProgress /> )
                )
            }

            { ongoingCourses.length == 1 && pendingCourses.length == 0 && (
                ongoingCourses.splice(0, 2).map((task) => <TaskCard task={ task } key={`dashboardtask${ task.id }`} withProgress /> )
                )
            }
            
            { ongoingCourses.length == 0 && pendingCourses.length > 0 && (
                pendingCourses.splice(0, 2).map((task) => <TaskCard task={ task } key={`dashboardtask${ task.id }`} /> )
                )
            }

            {/* SPLIT TO HALF */}
            { ongoingCourses.length == 1 && pendingCourses.length > 0 && (
                ongoingCourses.map((task) => <TaskCard task={ task } key={`dashboardtask${ task.id }`} withProgress/> )
                )
            }
            { ongoingCourses.length == 1 && pendingCourses.length > 0 && (
                pendingCourses.splice(0, 1).map((task) => <TaskCard task={ task } key={`dashboardtask${ task.id }`} /> )
                )
            }


        </div>
    )
}

export { DashboardTasks }
