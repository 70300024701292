import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import Skeleton from '@components/skeleton_loader'

import { getAchievements } from '@actions/course.actions'
import tableMap from './achievement_table.map'
import * as config from '@constants/config'
import './achievements.scss'
const Achievements = () => {
    const { user } = useSelector(state => state)
    const [ courses, setCourses ] = useState([])
    const [ loading, setLoading ] = useState(true)
    useEffect(() => {

        getAchievements(user.id)
            .then(res => {
                if (res.courses) setCourses(res.courses)
            })
            .finally(() => setLoading(false))
    }, [])

    return (
        <div id="achievementsPage">
            <div className="txt-header-2 txt-bold">
                Achievements
            </div>
            {
                loading ? (
                    <Skeleton
                        marginTop={ 50 }
                        count={ 7 }
                        boneStyles={{ height: 70 }}
                    />
                ) : (
                    <div className="achievements-table mt-50">
                        <div className="at-row bg-black px-30 py-25">
                            {
                                tableMap.map((row, index) => (
                                    <div key={`thead-${ index }`} className="txt-medium-large txt-white txt-bold">
                                        { row.label }
                                    </div>
                                ))
                            }
                            <div />
                        </div>
                        {
                            courses.length ? (
                                courses.map(course => (
                                    <div
                                        key={`achivement-${ course.id }`}
                                        className="at-row px-30 py-25"
                                    >
                                        {
                                            tableMap.map((row, index) => (
                                                <div key={`trow-${ course.id }-${ index }`} className="txt-medium-large txt-bold-semi">
                                                    { row.value(course) }
                                                </div>
                                            ))
                                        }
                                        <div>
                                            <a
                                                className="btn btn-icon py-15 px-20"
                                                style={{ background: "#D6D6D6" }}
                                                href={`${config.ASSET_URL}download/${ user.id }/certificate/${ course.id }`}
                                            >
                                                <i className="fas fa-download icon txt-error" />
                                            </a>
                                        </div>
                                    </div>
                                ))
                            ): (
                                <div className="p-30 mt-50 txt-large txt-bold txt-error txt-center">
                                    You don't have any achievements yet.
                                </div>
                            )
                        }
                    </div>
                )
            }
        </div>
    )
}

export default Achievements