import Index from '@pages'

const indexRoutes = {
    routeProps: {
        key: "index",
        exact: true,
        path: '/'
    },
    component: <Index />
}

export default indexRoutes