import React from 'react'
import { Link } from 'react-router-dom'

const DashboardQuicklinks = (props) => {

    return (
        <div className="dashboard-quicklinks mt-40">
            <div className="txt-black txt-large">
                QUICK LINKS
            </div>
            <div className="grid grid-2 grid-gap-25 mt-15">
                {
                    quicklinks.map((link, index) => (
                        <div key={`dashboardquicklink${ index }`}>
                            <Link
                                to={ link.to }
                                className="d-block bg-dark-gray p-35 txt-white btn-arrow"
                            >
                                <div className="flex align-center">
                                    <i className={`fas fa-${ link.icon } txt-h1`}/>
                                    <div className="ml-20 flex-1">
                                        <div className="flex align-center space-between txt-bold txt-xxlarge">
                                            <div>
                                                { link.title }
                                            </div>
                                            <i  className="fas fa-chevron-circle-right txt-large arrow" />
                                        </div>
                                        <div className="txt-bold-semi txt-large">
                                            { link.subtitle( props ) }
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

const quicklinks = [
    {
        title: "Explore Courses",
        subtitle: ({ courses }) => {
            const total = courses.on_going.length + courses.done.length + courses.pending.length
            return `${ total } Free Course${ total > 1 ? 's' : ''} Available`
        },
        icon: "books",
        to: "/courses"
    },
    {
        title: "Online Resources",
        subtitle: ({ courses }) => {
            let total = 0
            const statuses = ["on_going", "done", "pending"]

            statuses.forEach(status => {
                if (courses[status].length) {
                    courses[status].forEach(course => {
                        
                        if (course.asset_groups && course.asset_groups.length) total += course.asset_groups[0].assets.length
                    })
                }
            })

            return `${ total } Downloadable Material${ total > 1 ? 's' : ''}`
        },
        icon: "folders",
        to: "/resources"
    }
]
export { DashboardQuicklinks }
