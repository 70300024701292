import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import AccountLayout from '../layout'

import Skeleton from '@components/skeleton_loader'
import { connect } from '@helpers/api.helper'

const CookiePolicy = () => {
    const [ loading, setLoading ] = useState(true)
    const [ policy, setPolicy ] = useState(null)

    useEffect(() => {
        connect().get('/page/cookie')
            .then(res => {
                if (res.data) setPolicy(res.data)
            })
            .finally(() => setLoading(false))
    }, [])

    return (
        <AccountLayout>
            <div id="settingsWrapper">
                {
                    loading ? (
                        <Skeleton
                            count={ 10 }
                            boneStyles={{ height: 50 }}
                        />
                    ) : (
                        policy.content ? (
                            <>
                                <Link to="/account/settings">
                                    <div className="flex align-center pb-25" style={{ borderBottom: "1px solid #A4A4A480" }}>
                                        <i className="far fa-chevron-left txt-error txt-medium-large mr-15" />
                                        <div className="txt-xxlarge txt-error txt-bold-semi">
                                            { policy.content.title }
                                        </div>
                                    </div>
                                </Link>
                                <div
                                    className="mt-20 txt-medium-large"
                                    dangerouslySetInnerHTML={{ __html: policy.content.content }}
                                />
                            </>
                        ) : (
                            <div className="txt-error txt-bold txt-large txt-center pad-30 mt-50">
                                Sorry, but it seemst that this page is under construction.
                            </div>
                        )
                    )
                }
            </div>
        </AccountLayout>
    )
}

export default CookiePolicy