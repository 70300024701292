import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ExamCard, ExamCTAs, ExamProgress, ExamResult } from './components'

import { getExamSession, updateItem } from '@actions/course.actions'
const Exam = () => {
    const history = useHistory()
    const { id } = useParams()
    const { user } = useSelector(state => state)
    const [ currentQuestion, setCurrentQuestion ] = useState(0)
    const [ answers, setAnswers ] = useState({})
    const [ exam, setExam ] = useState(null)
    const [ result, setResult ] = useState('')
    const [ isLoading, setIsLoading ] = useState(true)

    const handleSelectAnswer = ({ question, answer }) => {
        setAnswers( prev => {
            const newAnswers = { ...prev }
            newAnswers[question] = answer
            return newAnswers
        })
    }
    const handleChangeQuestion = (action) => {
        let newQuestion = currentQuestion
        const lastQuestion = exam.items.length - 1
        const currentItem = exam.items[currentQuestion]
        const currentAnwer = answers[currentQuestion]
        
        if (action === "next") newQuestion += 1
        else newQuestion -= 1

        if ( newQuestion < 0 ) newQuestion = 0
        setIsLoading(true)
        updateItem(currentItem.id, { answer: currentAnwer, withResult: newQuestion > lastQuestion })
            .then((res) => {
                if (res.exam_result) {
                    const score = (+res.exam_result.score / +res.exam_result.number_of_items) * 100
                    setResult(score.toFixed(2))
                }
                else setCurrentQuestion(newQuestion)
            })
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        const sessionToken = sessionStorage.getItem("exam")
        if (sessionToken) {
            window.onbeforeunload = function(event) {
                const e = event || window.event
                // Cancel the event
                e.preventDefault()
                if (e) e.returnValue = '' // Legacy method for cross browser support
                return '' // Legacy method for cross browser support
            }
            window.onunload = function() { sessionStorage.removeItem('exam') }
            getExamSession(sessionToken).then(res => {
                if (res && res.data) setExam(res.data)
            })
            .finally(() => setIsLoading(false))
        }
        else history.goBack()

        return () => {
            window.onbeforeunload = null
        }
    }, [])

    return result ? (
        <ExamResult
            score={ +result }
            passing={ +exam.passing_percentage }
            userId={ user.id }
            courseId={ exam.course_id }
        />
    ) : (
        exam ? (
            <div id="coursePage" className="pos-relative">
                <div className="txt-header-2 txt-bold">
                    { exam.course.name }
                </div>
                <div
                    className="txt-large txt-bold-semi mt-10"
                    dangerouslySetInnerHTML={{ __html: exam.course.description }}
                />
                <ExamCard
                    currentQuestion={ currentQuestion }
                    qa={ exam.items[currentQuestion] }
                    selectedAnswer={ answers[currentQuestion] }
                    onSelectAnswer={ handleSelectAnswer }
                />
                <ExamProgress
                    qas={ exam.items }
                    currentQuestion={ currentQuestion }
                />
                <ExamCTAs
                    onChangeQuestion={ handleChangeQuestion }
                    nextEnabled={ answers[currentQuestion] !== undefined ? true : false }
                    lastQuestion={ (currentQuestion + 1) === exam.items.length }
                    firstQuestion={ currentQuestion === 0 }
                    isLoading={ isLoading }
                />
                {
                    isLoading ? (
                        <div className="loading-overlay">
                            <i className="fas fa-circle-notch txt-error" />
                        </div>
                    ) : null
                }
            </div>
        ) : null
    )
}

export default Exam