import React from 'react'

import { AchievementCard } from './components'

const AchievementList = ({ courses }) => {

    return (
        <div className="mt-10">
            <div className="txt-gray txt-large">
                Here are your certificates upon finishing each courses.
            </div>
            {
                courses.map((ach) => (
                    <div key={`achcard${ ach.id }`} >
                        <AchievementCard course={ ach }/>
                    </div>
                ))
            }
        </div>
    )
}

export { AchievementList }