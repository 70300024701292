import React, { useState, useEffect } from 'react'
import { parse, stringify } from 'query-string'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { CourseFilters, CourseList, TasksSummary, CoursesLoader } from './components'
import { getCourses } from '@actions/course.actions'

import './courses.scss'

const Courses = () => {
    const history = useHistory()
    const location = useLocation()
    const { user } = useSelector(state => state)
    const [ courses, setCourses ] = useState(null)
    const [ loading, setLoading ] = useState(true)
    const [ queryParams, setParams ] = useState(history.location.search)
    
    useEffect(() => {
        const params = parse( history.location.search )
        setParams(params)
        params.examinee_id = user.id

        setLoading(true)
        getCourses(user.brand_id, params)
            .then(res => {
                if (res.data) setCourses(res.data)
            })
            .finally(() => setLoading(false))
    }, [location.search])
    
    return (
        <div id="coursesPage">
            <div className="txt-header-2 txt-bold">
                Browse Courses Here
            </div>
            <CourseFilters
                filter={ queryParams.filter ? queryParams.filter : "all" }
                onFilterChange={ newFilter => {
                    const params = parse( history.location.search )
                    if (params.page) delete params.page
                    params.filter = newFilter

                    history.push(`/courses?${ stringify(params) }`)
                }}
            />
            <div className="grid grid-layout-wrapper courses-grid pt-20">
                {
                    !loading ? (
                        <CourseList
                        courses={ courses }
                        onSetParams={ setParams }
                    />
                    ) : <CoursesLoader />
                }
                {
                    courses ? (
                        <TasksSummary
                            courses={ courses }
                        />
                    ) : null
                }
            </div>
        </div>
    )
}

export default Courses