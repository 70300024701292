import React from 'react'

import './layout_body.scss'
const LayoutBody = ({ children }) => {

    return (
        <div id="layoutBody" className="flex-1 p-50 flex direction-column">
            { children }
        </div>
    )
}

export { LayoutBody }