import React from 'react'
import { Link } from 'react-router-dom'
import AccountLayout from '../layout'

import links from './settings_links.map'

const Settings = () => {

    return (
        <AccountLayout>
            <div id="settingsWrapper">
                <div className="txt-xxlarge txt-error txt-bold-semi">
                    Settings
                </div>
                {
                    links.map((link, index) => (
                        <div key={`settings-link-${ index }`} className="mt-15">
                            <Link to={ link.to } >
                                <div className="account-settings-link py-15 flex align-center space-between">
                                    <div>
                                        <div className="txt-large txt-bold-semi">
                                            { link.title }
                                        </div>
                                        <div className="txt-919191 txt-medium-large mt-10">
                                            { link.subtitle }
                                        </div>
                                    </div>
                                    <i className="far fa-chevron-right txt-large" />
                                </div>
                            </Link>
                        </div>
                    ))
                }
            </div>
        </AccountLayout>
    )
}

export default Settings