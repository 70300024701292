import dashboard from './dashboard.route'
import courses from './courses.route'
import login from './login.route'
import index from './index.route'
import resources from './resources.route'
import achievements from './achievements.route'
import faqs from './faqs.route'
import account from './account.route'

const routes = [
    index,
    ...login,
    dashboard,
    ...courses,
    resources,
    achievements,
    faqs,
    ...account
]

export default routes;