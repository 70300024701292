import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { parse } from 'query-string'

import { ResourcesList, ResourceFilters, ResourcesLoader, ResourcesSorting } from './components'
import { getResources } from '@actions/course.actions'

import './resources.scss'

const Resources = () => {
    const { user } = useSelector(state => state)
    const [ loading, setLoading ] = useState(false)
    const [ resources, setResources ] = useState({})
    const [ activeFilter, setActiveFilter ] = useState('')
    const [ activeOtherFilter, setActiveOtherFilter ] = useState('')
    const [ activeSort, setActiveSort ] = useState('')
    const [ currentPage, setCurrentPage ] = useState(1)
    const history = useHistory()

    useEffect(() => {
        setCurrentPage(prev => {
            const search = parse(history.location.search)
            return search.page ? search.page : 1
        })
    }, [])

    useEffect(() => {
        setLoading(true)
        const search = parse(history.location.search)
        const params = {
            examinee_id: user.id,
            filter_type: activeFilter,
            filter: activeOtherFilter,
            sort: activeSort,
            page: search.page ? search.page : 1
        }
        if (user.id) {
            getResources(user.brand.id, params)
                .then(res => {
                    if (res.resources) setResources(res.resources)
                })
                .finally(() => setLoading(false))
        }
    }, [activeFilter, activeOtherFilter, currentPage, activeSort])

    return (
        <div id="resourcesPage">
            <div className="txt-header-2 txt-bold">
                Downloadable Resources
            </div>
            <div className="grid grid-layout-wrapper pt-20 resources-grid">
                <div className="resources-list">
                    {
                        !loading ? (
                            resources.total ? (
                                <ResourcesList
                                    resources={ resources }
                                    onPaginate={ newPage => setCurrentPage(newPage) }
                                />
                            ) : (
                                <div className="txt-center p-30 mt-50 txt-bold txt-large txt-error">
                                    There are no available resources.
                                </div>
                            )
                        ) : (
                            <ResourcesLoader />
                        )
                    }
                </div>
                <div className="resources-filters">
                    <ResourceFilters
                        activeFilter={ activeFilter }
                        activeOtherFilter={ activeOtherFilter }
                        onFilterChange={ filter => {
                            history.push(history.location.pathname)
                            setActiveFilter(filter)
                        }}
                        onOtherFilterChange={ filter => {
                            history.push(history.location.pathname)
                            setActiveOtherFilter(filter)
                        }}
                    />
                    <ResourcesSorting
                        activeSort={ activeSort }
                        onSortChange={ sort => {
                            history.push(history.location.pathname)
                            setActiveSort(sort)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default Resources