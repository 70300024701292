import React from 'react'

import {
    AboutCourse,
    CourseActiveModule,
    CourseResources
} from './components'

const CourseDetails = () => {

    return (
        <div>
            <CourseActiveModule/>
            <CourseResources />
            <AboutCourse />
        </div>
    )
}

export { CourseDetails }