import Dashboard from '@pages/dashboard'

const dashboardRoute = {
    routeProps: {
        key: "dashboard",
        exact: true,
        path: '/dashboard'
    },
    component: <Dashboard />
}

export default dashboardRoute