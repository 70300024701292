import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { extractAssetObj } from '@helpers/image.helper'
import { Button } from '@components/form'

const DashboardBanner = ({ courses }) => {
    const { user } = useSelector(state => state)
    const history = useHistory()

    const sortedOngoingCourses = [...courses.on_going].sort((a, b) => {
            if ((a.examinee_modules[a.examinee_modules.length - 1]).updated_at > (b.examinee_modules[b.examinee_modules.length - 1]).updated_at){
                return -1;
            }
            if((a.examinee_modules[a.examinee_modules.length - 1]).updated_at < (b.examinee_modules[b.examinee_modules.length - 1]).updated_at){
                return 1;
            }
            return 0;
    });

    const [ongoingCourse, setOngoingCourse] = useState(sortedOngoingCourses[0]);

    useEffect(() => {
        const sortedOngoingCoursesRe = [...courses.on_going].sort((a, b) => {
            if ((a.examinee_modules[a.examinee_modules.length - 1]).updated_at > (b.examinee_modules[b.examinee_modules.length - 1]).updated_at){
                return -1;
            }
            if((a.examinee_modules[a.examinee_modules.length - 1]).updated_at < (b.examinee_modules[b.examinee_modules.length - 1]).updated_at){
                return 1;
            }
            return 0;
        });

        setOngoingCourse(sortedOngoingCoursesRe[0])
    }, [courses])


    return (
        <div
            className="dashboard-banner of-hidden"
            style={{ backgroundImage: `url(${ extractAssetObj(user.brand, ["background", "path"]) })`}}
        >
            <div className="px-30 py-40 txt-white">
                <div className="txt-bold txt-h1">
                    WELCOME, { user.first_name }!
                </div>
                <div className="txt-large txt-bold-semi mt-5">
                    {
                        courses.on_going.length ? `By clicking the button, you will automatically be directed to your current ongoing course.` : (
                            courses.pending.length && courses.done.length ? "By clicking the button, you will be directed to the list of courses." : (
                                courses.pending.length ? `To start using the system, click the "Get Started" button.` : (
                                    courses.done.length ? `Congratulations for finishing all the courses.` : ''
                                )
                            )
                        )
                    }
                </div>
                {
                    courses.on_going.length ? (
                        <Button
                            className="mt-40 txt-xlarge"
                            onClick={ () => history.push(`/courses/${ ongoingCourse.id }`) }
                        >
                            CONTINUE THE COURSE
                        </Button>
                    ) : (
                        courses.pending.length ? (
                            <Button
                                className="mt-40 txt-xlarge"
                                onClick={ () => history.push(`/courses`) }
                            >
                                { courses.done.length  ? "START A NEW COURSE" : "GET STARTED" }
                            </Button>
                        ) : null
                    )
                }
            </div>
        </div>
    )
}

export { DashboardBanner }