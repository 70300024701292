import React from 'react'
import { Link } from 'react-router-dom'
import CourseImage from '@components/course_image'
const TaskCard = ({ task, withProgress }) => {
    const moduleLength = task.modules.length
    const progress = withProgress ? ((task.examinee_modules.filter(examModule => examModule.module).length) / task.modules.length) * 100 : 0
    const currentModule = progress ? (task.examinee_modules.filter(examModule => examModule.module).length):0
    
    return (
        <div className="task-card bg-dark-gray p-25 flex mt-20 txt-white br-5">
            <div>
                <CourseImage course={ task } className="task-image" />
            </div>
            <div className="ml-20 flex-1">
                <div className="flex align-center">
                    <Link to={`/courses/${ task.id }`}>
                        <div className="txt-bold txt-xxlarge flex-1">
                            { task.name }
                        </div>
                    </Link>
                </div>
                { withProgress ? (
                        <div className="txt-bold-semi txt-large mt-5">
                            In progress - Module { currentModule } of { moduleLength }
                        </div>
                    ) : (
                        <div className="txt-bold-semi txt-large mt-5">
                            MODULES 1-{ moduleLength } (Including { moduleLength } Video{ moduleLength > 1 ? 's' : '' })
                        </div>
                    )
                }
                {
                    withProgress ? (
                        <div className="flex align-center mt-20">
                            <div className="task-progress-bar flex-1 position-relative">
                                <div
                                    className="position-absolute"
                                    style={{
                                        maxWidth: `${ progress }%`
                                    }}
                                />
                            </div>
                            <div className="task-progress-count txt-bold txt-right">
                                { progress.toFixed(2) / 1 }%
                            </div>
                        </div>
                    ) : (
                        <div className="mt-20 flex flex-end">
                            <Link
                                to={`/courses/${ task.id }`}
                                className="btn-clear btn-arrow pl-10 txt-secondary-bold flex align-center txt-white"
                            >
                                <span className="txt-large">
                                    START THIS COURSE
                                </span>
                                <i  className="fas fa-chevron-circle-right ml-15 arrow"/>
                            </Link>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export { TaskCard }