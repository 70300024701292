import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
    DashboardBanner,
    DashboardTasks,
    DashboardQuicklinks,
    DashboardAchievements,
    DashboardLoader
} from './components'
import './dashboard.scss'
import { getCoursesStatus } from '@actions/course.actions'

const Dashboard = () => {
    const { user } = useSelector(state => state)
    const [ isLoading, setIsLoading ] = useState(true)
    const [ courses, setCourses ] = useState({})

    useEffect(() => {
        getCoursesStatus(user.id)
            .then(res => {
                if ( res.data ) setCourses(res.data)
            })
            .finally(() => setIsLoading(false))
    }, [])
    
    return (
        <div id="dashboardPage" className="grid grid-layout-wrapper dashboard-grid">
            {
                isLoading ? (
                    <DashboardLoader />
                ) : (
                    <>
                        <div>
                            <DashboardBanner courses={ courses } />
                            <DashboardTasks courses={ courses } />
                            <DashboardQuicklinks courses={ courses } />
                        </div>
                        <div>
                            <DashboardAchievements completedCourses={ courses.done }/>
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default Dashboard