import FAQs from '@pages/faqs'

const faqsRoutes = {
    routeProps: {
        key: "faqs",
        exact: true,
        path: '/faqs'
    },
    component: <FAQs />
}

export default faqsRoutes