import React from 'react'
import { useSelector } from 'react-redux'

import { extractAssetObj } from '@helpers/image.helper'

const CourseImage = ({ course, className }) => {
    const { brand } = useSelector( state => state.user )
    
    return (
        <img
            alt={ course.name }
            src={ course.image ? extractAssetObj( course ) : extractAssetObj( brand, ["background", "path"] ) }
            className={ className }
        />
    )
}

export default CourseImage