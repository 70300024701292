const links = [
    {
        title: "Your Account",
        subtitle: "See information about your account.",
        to: "/account/settings/profile"
    },
    {
        title: "Cookie Policy",
        subtitle: "Learn more how your data is being stored.",
        to: "/account/settings/cookie-policy"
    }
]

export default links