import React from 'react'

import Sekeleton from '@components/skeleton_loader'

const CoursesLoader = () => {

    return (
        <div>
            <Sekeleton
                count={ 3 }
                boneStyles={{
                    height: 150
                }}
            />
        </div>
    )
}

export { CoursesLoader }