import { connect } from '@helpers/api.helper'

export function getCourses(brandId, params, cancelToken) {
    return connect().get(`/courses/${ brandId }`, { params, cancelToken: (cancelToken ? cancelToken.token : null) })
}

export function findCourse(courseId, params, cancelToken) {
    return connect().get(`/courses/find/${ courseId }`, { params, cancelToken: (cancelToken ? cancelToken.token : null) })
}

export function markModuleAsDone(data) {
    return connect().post('/courses/module/update', data)
}

export function getDoneModules(params) {
    return connect().get('/courses/modules/get', {params})
}

export function getCoursesStatus(examinee_id) {
    return connect().get(`/course-status/get/${ examinee_id }`)
}

export function startExamSession(data, cancelToken) {
    return connect().post(`/exam/initialize`, data, { cancelToken: (cancelToken ? cancelToken.token : null) })
}

export function getExamSession(token, cancelToken) {
    return connect().get(`/exam/find/${ token }`, { cancelToken: (cancelToken ? cancelToken.token : null) })
}

export function updateItem(itemId, data) {
    return connect().post(`/session-item/${ itemId }`, data)
}

export function downloadResource(resourceId) {
    return connect().get(`/resources/download/${ resourceId }`)
}

export function getResources(brandId, params) {
    return connect().get(`/courses/resources/${ brandId }`, { params })
}

export function getAchievements(examineeId) {
    return connect().get(`/achievements/${ examineeId }`)
}