import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import AccountLayout from '@pages/account/layout'
import { UserProfile } from './components'

import profileFields from './user_profile.map'
import profileRules from './user_profile.rules'

import passwordFields from './user_password.map'
import passwordRules from './user_password.rules'

const Profile = () => {
    const { user } = useSelector(state => state)

    return (
        <AccountLayout>
            <div id="settingsWrapper">
                <Link to="/account/settings">
                    <div className="flex align-center pb-25" style={{ borderBottom: "1px solid #A4A4A480" }}>
                        <i className="far fa-chevron-left txt-error txt-medium-large mr-15" />
                        <div className="txt-xxlarge txt-error txt-bold-semi">
                            Your Account
                        </div>
                    </div>
                </Link>
                <UserProfile
                    initialValue={ user }
                    fields={ profileFields }
                    rules={ profileRules }
                />
                <UserProfile
                    initialValue={ user }
                    fields={ passwordFields }
                    rules={ passwordRules }
                    type="password"
                />
            </div>
        </AccountLayout>
    )
}

export default Profile