const profileFields = [
    {
        label: "Full Name",
        name: "first_name",
    },
    {
        grid: true,
        fields: [
            {
                label: "Email Address",
                name: "email"
            },
            {
                label: "Contact Number",
                name: "contact_number"
            }
        ]
    },
    {
        label: "Position",
        name: "position",
    },
    {
        label: "Address",
        name: "address",
    }
]

export default profileFields