import React, { useRef } from 'react'

const ProgressRing = ({
    radius,
    stroke,
    color = "#fff",
    progress = 100,
    labelClass
}) => {
    const normalizedRadius = useRef(radius - (stroke / 2)).current
    const circumference = useRef(normalizedRadius * 2 * Math.PI).current
    const strokeDashoffset = circumference - progress / 100 * circumference

    return (
        <div className="pos-relative">
            <svg
                height={radius * 2}
                width={radius * 2}
            >
                <circle
                    stroke={ color }
                    fill="transparent"
                    strokeWidth={ stroke }
                    strokeDasharray={ circumference + ' ' + circumference }
                    style={ { strokeDashoffset } }
                    r={ normalizedRadius }
                    cx={ radius }
                    cy={ radius }
                />
            </svg>
            <div className={`pos-absolute ${ labelClass } flex align-center justify-center`} style={{ left: 0, top: 0, width: "100%", height: "100%" }}>
                { progress }%
            </div>
        </div>
    )
}

export default ProgressRing