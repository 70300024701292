import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const CourseFilters = ({ filter = "all", onFilterChange }) => {
    const [ indicatorPos, setIndicatorPos ] = useState({})
    const history = useHistory()

    useEffect(() => {   
        const activeFilter = document.querySelector(`[filter="${ filter }"]`)
        if ( activeFilter ) {
            setIndicatorPos({
                maxWidth: activeFilter.clientWidth,
                left: activeFilter.offsetLeft
            })
        }
    }, [filter])

    return (
        <div className="pos-relative course-filters">
            <div className="flex align-center pt-40">
                <button className="btn btn-clear mr-20" onClick={ () => onFilterChange("all") }>
                    <div className="txt-bold txt-large" filter="all">
                        All Courses
                    </div>
                </button>
                <button className="btn btn-clear mr-20" onClick={ () => onFilterChange("complete") }>
                    <div className="txt-bold txt-large" filter="complete">
                        Completed
                    </div>
                </button>
                <button className="btn btn-clear" onClick={ () => onFilterChange("ongoing") }>
                    <div className="txt-bold txt-large" filter="ongoing">
                        Ongoing
                    </div>
                </button>
            </div>
            <div
                className="filter-indicator mt-5 pos-relative"
                style={ indicatorPos }
            />
        </div>
    )
}

export { CourseFilters }