import React from 'react'

const EmptyMessage = () => {

    return (
        <div className="mt-20 p-45 bg-dark-gray txt-center txt-bold-semi txt-white br-5">
            <i className="fas fa-trophy txt-oversized"/>
            <div className="txt-xxlarge mt-30 txt-bold">
                No Available Certificates Yet
            </div>
            <div className="mt-10 txt-large" style={{ lineHeight: `30px` }}>
                As you finish each course, your certificate will be
                <br />
                shown here for your easy download experience.
            </div>
        </div>
    )
}

export { EmptyMessage }