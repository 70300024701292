import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Skeleton from '@components/skeleton_loader'
import ProgressRing from '@components/progress_ring'
import { getCoursesStatus } from '@actions/course.actions'
const TasksSummary = () => {
    const [ done, setDone ] = useState(0)
    const [ onGoing, setOnGoing ] = useState(0)
    const [ pending, setPending ] = useState(0)
    const { user } = useSelector(state => state)
    const [ loading, setLoading ] = useState(true)
    const totalAssigned = done + pending + onGoing
    
    useEffect(() => {
        getCoursesStatus(user.id)
            .then(res => {
                if (res && res.data) {
                    setDone(res.data.done.length)
                    setOnGoing(res.data.on_going.length)
                    setPending(res.data.pending.length)
                }
            })
            .finally(() => setLoading(false))
    }, [])

    return loading ? (
        <div>
            <Skeleton
                count={ 1 }
                bondStyles={{ height: 200 }}
            />
        </div>
    ) : (
        <div>
            <div className="tasks-summary">
                <div className="tasks-summary-header txt-large txt-bold txt-white py-15 px-20">
                    COURSES SUMMARY
                </div>
                <div className="flex align-center py-20 pl-25 pr-30">
                    <div>
                        <ProgressRing
                            radius={ 50 }
                            stroke={ 20 }
                            color="#9B5BA5"
                            progress={ ((done / totalAssigned) * 100).toFixed(1) / 1 }
                            labelClass="txt-large txt-bold"
                        />
                    </div>
                    <div className="pl-25">
                        <div className="txt-error txt-bold txt-large">
                            OUT OF { totalAssigned } COURSE{ totalAssigned > 1 ? 'S' : '' }, YOU HAVE:
                        </div>
                        <div className="flex align-center mt-5">
                            <div className="task-dot" />
                            <div className="txt-large ml-10">
                                { done } Completed Course{ done > 1 ? 's' : '' }
                            </div>
                        </div>
                        <div className="flex align-center mt-5">
                            <div className="task-dot" />
                            <div className="txt-large ml-10">
                                { onGoing } Ongoing Course{onGoing > 1 ? 's' : '' }
                            </div>
                        </div>
                        <div className="flex align-center mt-5">
                            <div className="task-dot" />
                            <div className="txt-large ml-10">
                                { pending } Course{ pending > 1 ? 's' : '' } Remaining
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { TasksSummary }