import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { parse } from 'query-string'

const SearchInput = () => {
    const [ keyword, setKeyword ] = useState('')
    const history = useHistory()

    useEffect(() => {
        const qryparams = parse(history.location.search)
        if (qryparams.keyword) setKeyword(qryparams.keyword)
    }, [])

    return (
        <div className="flex-1 search-margin">
            <div className="search-wrapper txt-medium-large">
                <div className="pos-relative flex align-center">
                    <input
                        type="text"
                        className="py-10 px-15"
                        placeholder="Search Learning here"
                        value={ keyword }
                        onChange={ ({target}) => setKeyword(target.value) }
                        onKeyDown={ e => {
                            if (e.key === "Enter") history.push(`/courses?keyword=${ keyword }`)
                        }}
                    />
                    <i className="fal fa-search txt-medium-large pr-10"/>
                </div>
            </div>
        </div>
    )
}

export { SearchInput }