class CourseFactory {

    constructor(course) {
        Object.keys(course).map(key => typeof course[key] !== "function" ? this[key] = course[key] : false);
    }

    parseModuleDuration = (duration) => {
        const splitDuration = duration.split(":")

        if (splitDuration.length === 2) {
            const minutes = splitDuration[0] ? splitDuration[0] : 0
            const seconds = splitDuration[1] ? splitDuration[1] : 0
            
            return +minutes + (+seconds / 60)
        }
        else {
            const hours = splitDuration[0] ? splitDuration[0] : 0
            const minutes = splitDuration[1] ? splitDuration[1] : 0
            const seconds = splitDuration[2] ? splitDuration[2] : 0
            
            return (+hours * 60) + +minutes + (+seconds / 60)
        }
    }
    
    getTotalDuration = () => {
        let total = 0
        JSON.parse(JSON.stringify(this.modules)).forEach(module => {
            if (module.id) total += this.parseModuleDuration(module.duration)
        })

        total = Math.ceil(total)
        if (total > 60) {
            const hours = Math.floor(total / 60)
            total = `${ hours }+ HOUR${ hours > 1 ? 'S' : '' }`
        }
        else total = `${ total } MIN${ total > 1 ? 'S' : '' }`

        return total
    }
}

export default CourseFactory;