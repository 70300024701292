import React from 'react'

import { EmptyMessage, AchievementList } from './components'

const DashboardAchievements = ({ completedCourses = [] }) => {

    return (
        <div className="dashboard-achievements">
            <div className="txt-black txt-large">
                Achievements
            </div>
            { completedCourses.length ? <AchievementList courses={ completedCourses }/> : <EmptyMessage /> }
        </div>
    )
}

export { DashboardAchievements }
