import React, { useState, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getDoneModules } from '@actions/course.actions'
import CourseContext from '@contexts/course.context'
const CourseModuleList = () => {
    const [ isLoading, setIsLoading ] = useState(true)
    const { user } = useSelector(state => state)
    const { activeModule, modules, onModuleSelect, getTotalDuration, course, onUpdateModules } = useContext(CourseContext)

    useEffect(() => {
        getDoneModules({
            examinee_id: user.id,
            course_id: course.id
        })
        .then(res => {
            if (res && res.data) {
                let activeModuleIndex = 0
                onUpdateModules(prev => {
                    let newModules = [ ...prev ]

                    newModules.forEach((currentModule, index) => {
                        if (res.data.find(doneModule => doneModule.module_id === currentModule.id )) {
                            newModules[index].done = true
                            activeModuleIndex = index + 1
                        }
                    })
                    return newModules
                })
                
                onModuleSelect(activeModuleIndex)
            }
        })
        .finally(() => setIsLoading(false))
    }, [])

    return isLoading ? null : (
        <div>
            <div className="course-module-list flex direction-column">
                <div className="course-module-list-header flex space-between txt-medium txt-white p-20">
                    <div className="txt-bold">
                        MODULES ({ activeModule + 1 }/{ modules.length })
                    </div>
                    <div className="txt-bold-semi">
                        { getTotalDuration() }
                    </div>
                </div>
                <div className="flex-1 of-auto">
                    {
                        modules.map((module, i) => {
                            const enabled = (i - activeModule) <= 1 || module.done || (modules[ i - 1] && modules[ i - 1].done)

                            return (
                                <div
                                    key={`coursemodule-${ i }`}
                                    className={`course-module flex align-center p-20 ${ activeModule !== i  ? (module.done ? 'done' : '') : 'active txt-white' }`}
                                    role="button"
                                    onClick={ () => enabled ? onModuleSelect(i) : {} }
                                    style={{ opacity: enabled ? 1 : 0.5, pointerEvents: enabled ? 'auto' : 'none' }}
                                >
                                    <i className={`${ module.done ? 'fas fa-check-circle' : `far fa-${ activeModule === i ? 'play' : 'pause' }-circle` } txt-xlarge`}/>
                                    <div className={`txt-large txt-bold${ i > 0 ? '-semi' : ''} flex-1 px-15`}>
                                        { i + 1 }. { module.name }
                                    </div>
                                    <div className="txt-medium resource-duration">
                                        { module.duration } { i === (modules.length - 1) ? "item" : "mins" }{ i === (modules.length - 1) && module.duration > 1 ? 's' : ''}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export { CourseModuleList }