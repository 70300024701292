import React from 'react'

import { UserBox, AccountLinks } from './components'
import './account.scss'
const AccountLayout = ({ children }) => {

    return (
        <div id="accountPage">
            <div className="txt-header-2 txt-bold">
                Your Account
            </div>
            <div className="grid grid-account-wrapper account-grid">
                <div>
                    <UserBox />
                    <AccountLinks />
                </div>
                <div className="account-content-wrapper p-35">
                    { children }
                </div>
            </div>
        </div>
    )
}

export default AccountLayout