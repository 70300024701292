import React from 'react'

import Sekeleton from '@components/skeleton_loader'

const DashboardLoader = () => {

    return (
        <>
            <div>
                <Sekeleton
                    boneStyles={{
                        height: 300
                    }}
                />
                <Sekeleton
                    count={ 3 }
                    marginTop={ 30 }
                    boneStyles={{
                        height: 100
                    }}
                />
            </div>
            <div>
                <Sekeleton
                    count={ 5 }
                    boneStyles={{
                        height: 50
                    }}
                />
            </div>
        </>
    )
}

export { DashboardLoader }