import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import Accordion from '@components/accordion'
import * as config from '@constants/config'

const AchievementCard = ({ course }) => {
    const { user } = useSelector(state => state)
    const [ collapse, setCollapse ] = useState(true)
    const modulesCount = course.modules.length
    const courseScore = (course.examinee_done_session.score / course.examinee_done_session.number_of_items) * 100
    return (
        <div className="achievement-card mt-30" style={{ border: "1px solid #79797980" }}>
            <div
                className="bg-black py-10 px-20 flex align-center space-between txt-white txt-large txt-bold-semi" 
                role="button"
                onClick={ () => setCollapse(prev => !prev) }
            >
                <div>
                    { course.name }
                </div>
                <i className="far fa-chevron-down" />
            </div>
            <Accordion collapse={ collapse }>
                <div className="bg-fff px-20 py-15">
                    <div className="txt-gray txt-medium">
                        Finished on { moment( course.examinee_done_session.updated_at).format("MMMM DD, YYYY") }. Completed watching { modulesCount } video{ modulesCount > 1 ? 's' : '' } of { modulesCount } module{ modulesCount > 1 ? 's' : '' } and passed the post examination with { courseScore.toFixed(2) / 1 }% rate.
                    </div>
                    <div className="flex mt-15">
                        <div className="bg-F2F2F2 txt-error p-10 txt-bold-semi txt-medium txt-ellipsis txt-nowrap of-hidden flex-1">
                            <i className="fas fa-file mr-10"/> Certificate of Completion for the course { course.name }
                        </div>
                        <a
                            href={`${config.ASSET_URL}download/${ user.id }/certificate/${ course.id }`}
                            className="btn-icon bg-D9D9D9 txt-error px-15 br-5 ml-10 flex align-center"
                        >
                            <i className="fas fa-download icon" />
                        </a>
                    </div>
                </div>
            </Accordion>
        </div>
    )
}

export { AchievementCard }