import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import UserImage from '@components/user_image'

const UserBox = () => {
    const { user } = useSelector(state => state)

    return (
        <div className="user-box">
            <div className="bg-black txt-medium-large txt-bold txt-white p-15 flex align-center">
                <i className="fas fa-user" />
                <div className="flex-1 ml-10">
                    PERSONAL INFORMATION
                </div>
                <Link className="btn-icon" to="/account/settings/profile">
                    <i className="far fa-edit icon txt-white" />
                </Link>
            </div>
            <div className="px-30">
                <div className="txt-center py-35 user-details">
                    <UserImage
                        className="user-image"
                        containerClass="user-image"
                    />
                    <div className="txt-xlarge mt-20">
                        { user.first_name }
                    </div>
                    <div className="txt-large txt-secondary-medium-w txt-error">
                        { user.email }
                    </div>
                </div>
                <div className="py-35 user-contacts">
                    <div className="flex txt-large"  style={{ lineHeight: 1 }}>
                        <i className="fas fa-phone txt-error" style={{ width: 30 }}/>
                        <div className="txt-bold-semi">
                            { user.contact_number }
                        </div>
                    </div>
                    <div className="flex txt-large mt-20" style={{ lineHeight: 1 }}>
                        <i className="fas fa-map-marker-alt txt-error"  style={{ width: 30 }}/>
                        <div className="txt-bold-semi">
                            { user.address }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { UserBox }