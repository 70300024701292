import React, { useState, useEffect } from 'react'

import ControlGroup from './control_group'

import { validate } from '@helpers/validation.helper'
import { update } from '@actions/user.actions'

const UserProfile = ({ initialValue, fields, rules, type }) => {
    
    const [ loading, setLoading ] = useState(false)
    const [ errors, setErrors ] = useState({})
    const [ form, setForm ] = useState({})
    const [ submitted, setSubmitted ] = useState(false)
    const onChange = ({ name, value }) => {
        setForm(prev => {
            const newForm = { ...prev }
            newForm[name] = value

            return newForm
        })

        setErrors(prev => {
            const newErrors = { ...prev }
            newErrors[name] = ''

            return newErrors
        })
    }
    const onSubmit = () => {
        const validation = validate(form, rules)

        if (type === "password" && (form.humanized_password !== form.cpassword)) {
            validation.valid = false
            validation.errors = { ...validation.errors, cpassword: "Confirm password did not match" }
        } 

        setErrors(validation.errors)
        if (validation.valid) {
            setLoading(true)
            update(form, initialValue.id)
                .then(res => setSubmitted(true))
                .finally(() => setLoading(false))
        }
    }

    useEffect(() => { setForm(JSON.parse(JSON.stringify(initialValue))) }, [])
    useEffect(() => { 
        if (submitted) setTimeout(() => setSubmitted(false), 3000)
    }, [submitted])

    return (
        <div className="py-25 pos-relative" style={{ borderBottom: "1px solid #A4A4A480" }}>
            {
                fields.map(field => (
                    field.grid ? (
                        <div className="grid grid-2 grid-gap-30">
                            {
                                field.fields.map(gridField => (
                                    <ControlGroup
                                        field={ gridField }
                                        value={ form[gridField.name] }
                                        onChange={ onChange }
                                        error={ errors[gridField.name] }
                                        type={ type }
                                    />
                                ))
                            }
                        </div>
                    ) : (
                        <ControlGroup
                            field={ field }
                            value={ form[field.name] }
                            onChange={ onChange }
                            error={ errors[field.name] }
                            type={ type  }
                        />
                    )
                ))
            }
            <button
                className="btn btn-red mb-25 mt-50"
                onClick={ onSubmit }
            >
                SAVE CHANGES
            </button>
            {
                loading ? (
                    <div className="loading-overlay">
                        <i className="fas fa-circle-notch txt-error" />
                    </div>
                ) : null
            }
            {
                submitted ? (
                    <div
                        className="pos-fixed popup-alert txt-large txt-bold"
                    >
                        Successfully updated
                    </div>
                ) : null
            }
        </div>
    )
}

export { UserProfile }