import React from 'react'
import { Link } from 'react-router-dom'

import { logout } from '@actions/user.actions'
import links from './dropdown_links'

const DropdownMenu = ({ collapse, onSetCollapse }) => {

    return (
        <div id="userDropdownMenu" className={`pos-absolute ${ collapse ? 'collapse' : '' }`}>
            <div className="dropdown-links-wrapper">
                {
                    links.map((link, index) => (
                        <Link
                            className={`flex align-center dropdown-link txt-large ${ index > 0 ? "mt-15" : '' } btn-icon`}
                            key={`dropdownlink${ index }`}
                            to={ link.to }
                            onClick={ () => onSetCollapse(false) }
                        >
                            <i className={`${ link.icon } txt-error mr-15 icon`}/>
                            <div className="txt-gray txt-secondary-medium-w">
                                { link.label }
                            </div>
                        </Link>
                    ))
                }
            </div>
            <div
                className={`flex align-center dropdown-link txt-large p-20 btn-icon`}
                role="button"
                onClick={ logout }
            >
                <i className={`fas fa-sign-out txt-error mr-15 icon`}/>
                <div className="txt-gray txt-secondary-medium-w">
                    Logout
                </div>
            </div>
        </div>
    )
}

export { DropdownMenu }