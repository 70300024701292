import React from 'react'
import { useHistory } from 'react-router-dom'
const AccountLinks = () => {
    const history = useHistory()

    return (
        <div>
            {
                links.map((link, index) => {
                    const isActive = history.location.pathname.includes(link.to)
                    return (
                        <div
                            key={`accountlink-${ index }`}
                            className={`flex align-center txt-medium-large txt-white p-15 mt-25 account-link ${ isActive ? 'active' : '' }`}
                            role="button"
                            onClick={ () => history.push(link.to) }
                        >
                            <i className={ link.icon }/>
                            <div className="ml-15">
                                { link.label }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

const links = [
    {
        label: "REPORTS",
        icon: "fas fa-file",
        to: "/account/reports"
    },
    {
        label: "ACCOUNT SETTINGS",
        icon: "fas fa-cog",
        to: "/account/settings"
    }
]

export { AccountLinks }