import React from 'react'
import tableMap from '../reports_table..map'

import Skeleton from '@components/skeleton_loader'

const Table = ({ courses, loading }) => {

    return !loading ? (
        <div className="reports-table mt-25">
            <div className="at-row bg-black px-30 py-25">
                {
                    tableMap.map((row, index) => (
                        <div key={`thead-${ index }`} className="txt-medium-large txt-white txt-bold">
                            { row.label }
                        </div>
                    ))
                }
            </div>
            <div>
                {
                    courses.length ? (
                        courses.map(course => (
                            <div
                                key={`achivement-${ course.id }`}
                                className="at-row px-30 py-25"
                            >
                                {
                                    tableMap.map((row, index) => (
                                        <div key={`trow-${ course.id }-${ index }`} className="txt-medium-large txt-bold-semi">
                                            { row.value(course) }
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    ) : (
                        <div className="p-50 txt-center txt-error txt-bold txt-large">
                            You have no progress to report.
                        </div>
                    )
                }
            </div>
        </div>
    ) : (
        <Skeleton
            marginTop={ 25 }
            count={ 5 }
            boneStyles={{ height: 100 }}
        />
    )
}

export { Table }