import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { TextInput, Button } from '@components/form'

import { passwordReset } from '@actions/user.actions'
import '../login.scss'

const ForgotPassword = () => {
    const [form, setForm] = useState({ email: '' })
    const [ error, setError ] = useState('')
    const [ submitted, setSubmitted ] = useState(false)
    const [ loading, setLoading ] = useState(false)

    const handTextChange = ({ name, value }) => {
        if (!loading) {
            setForm(prev => {
                const newForm = { ...prev }
                newForm[name] = value
    
                return newForm
            })
            setSubmitted(false)
            setError('')
        }
    }

    const handleLogin = () => {
        setSubmitted(false)
        setLoading(true)
        setError('')
        passwordReset(form)
            .then(() => setSubmitted(true))
            .catch(() => setError('Invalid email'))
            .finally(() => setLoading(false))
    }

    return (
        <div
            id="loginPage"
            className="flex-1 flex direction-column"
            style={{ backgroundImage: `url("https://picsum.photos/1200/800")`}}
        >
            <div className="login-wrapper flex-1 of-auto flex align-center">
                <div className="login-scroll-control">
                    <div className="login-box bg-fff">
                        <div className="txt-bold txt-header-2">
                            FORGOT PASSWORD
                        </div>
                        <TextInput
                            name="email"
                            label="Email or Phone"
                            onChange={ handTextChange }
                            value={ form.email }
                            containerClass="mt-40"
                            onSubmit={ handleLogin }
                        />
                        {
                            error ? (
                                <div className="txt-error txt-bold mt-10">
                                    { error }
                                </div>
                            ) : null
                        }
                        {
                            submitted ? (
                                <div className="txt-success txt-bold mt-10 txt-center">
                                    Reset password successful.
                                    <br />
                                    Check your email for your new password.
                                </div>
                            ) : null
                        }
                        <div className="flex mt-40">
                            <Button
                                className="flex-1 d-block txt-xlarge"
                                styles={{ width: "100%" }}
                                onClick={ handleLogin }
                                disabled={ !form.email || loading }
                            >
                                RESET PASSWORD
                            </Button>
                        </div>
                        <div className="mt-10 txt-large txt-bold txt-center">
                            <Link
                                to="/login"
                            >
                                Back to login
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword