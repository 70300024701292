import React from 'react'
import moment from 'moment'

const table = [
    {
        label: "RESULT",
        value: (course) => {
            const outstanding = course.exam_sessions.length === 1

            return (
                <div className={ `txt-${ outstanding ? "success" : "warning" } txt-bold-semi` }>
                    { outstanding ? "Outstanding" : "Passed"} 
                </div>
            )
        }
    },
    {
        label: "COURSE TITLE",
        value: (course) => course.name
    },
    {
        label: "DATE ACCOMPLISHED",
        value: (course) => {
            const doneSession = course.exam_sessions.find(session => +session.is_done === 1)

            return moment( doneSession.updated_at ).format('MMMM DD, YYYY')
        }
    }
]

export default table