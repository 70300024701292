import React from 'react'

const Button = ({ children, className, onClick, disabled }) => {

    return (
        <button
            className={`btn txt-white txt-bold ${ className } ${ disabled ? "disabled" : '' }`}
            style={{ backgroundColor: "#ED1A3A"}}
            onClick={ onClick }
            disabled={ disabled }
        >
            { children }
        </button>
    )
}

export { Button }