import React from 'react'

const ControlGroup = ({field, value, error, onChange, type }) => {
    console.log(error)
    return (
        <div className="profile-control-group">
            <div className="mt-25 mb-15 txt-bold" style={{ fontSize: 20 }}>
                { field.label }
            </div>
            <input
                name={ field.name }
                value={ value }
                onChange={ ({ target }) => onChange(target) }
                className="txt-bold-semi"
                type={ type ? type : "text" }
            />
            { error ? <div className="txt-error txt-bold mt-10">{ error }</div> : null }
        </div>
    )
}

export default ControlGroup