import Login from '@pages/login'
import ForgotPassword from '@pages/login/reset_password'

const loginRoutes = [
    {
        routeProps: {
            key: "login",
            exact: true,
            path: '/login'
        },
        component: <Login />
    },
    {
        routeProps: {
            key: "forgot-password",
            exact: true,
            path: '/login/forgot-password'
        },
        component: <ForgotPassword />
    }
]
export default loginRoutes