import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import AccountLayout from '../layout'

import { Table } from './components'

import { getAchievements } from '@actions/course.actions'
import * as config from '@constants/config'
import './reports.scss'
const Reports = () => {
    const { user } = useSelector(state => state)
    const [ courses, setCourses ] = useState([])
    const [ loading, setLoading ] = useState(true)

    useEffect(() => {

        getAchievements(user.id)
            .then(res => {
                if (res.courses) setCourses(res.courses)
            })
            .finally(() => setLoading(false))
    }, [])

    return (
        <AccountLayout>
            <div id="reportsWrapper">
                <div className="flex space-between align-end">
                    <div>
                        <div className="txt-xxlarge txt-error txt-bold-semi">
                            Reports
                        </div>
                    </div>
                    {
                        courses.length ? (
                            <div>
                                <a
                                    className={`btn txt-white txt-bold`}
                                    style={{ backgroundColor: "#ED1A3A", display: "block" }}
                                    href={`${config.ASSET_URL}export/progress-report/${ user.id }`}
                                >
                                    EXPORT CSV
                                </a>
                            </div>
                        ) : null
                    }
                </div>
                <Table courses={ courses } loading={ loading } />
            </div>
        </AccountLayout>
    )
}

export default Reports