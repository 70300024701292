const formRules = {
    properties: {
        humanized_password: {
            type: 'string',
            required: true,
            allowEmpty: false,
            minLength: 8,
            messages: {
                required: 'This field is required',
                allowEmpty: "This field is required",
                minLength: 'Minimum of 8 characters'
            }
        }
    }
}

export default formRules