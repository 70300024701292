import React from 'react'
import moment from 'moment'

import PaginationLinks from '@components/pagination_links'
import { ResourceDownloadButton } from '@components/buttons'
import wordIcon from '@assets/images/icons/word-icon.svg'
import pdfIcon from '@assets/images/icons/pdf-icon.svg'
import fileIcon from '@assets/images/icons/file.png'

const ResourcesList = ({ resources, onPaginate }) => {

    return (
        <>
            <div className="course-resources-wrapper">
                {
                    resources.data.map(resource => (
                        <div
                            key={`courseres${ resource.id }-${ resource.asset.id }`}
                            className="course-resource flex align-center p-10"
                        >
                            <div className="resource-icon flex flex-end">
                                <img alt="" src={ resource.asset.type === "document" ? wordIcon : (resource.asset.type === "pdf" ? pdfIcon : fileIcon) }/>
                            </div>
                            <div className="flex-1 px-20 txt-bold-semi">
                                <div className="txt-large break-word">
                                    { resource.asset.name }
                                </div>
                                <div style={{ color: "#919191" }} className="txt-medium-large">
                                    { resource.group.reference.name }, { moment( resource.created_at ).format("MMMM DD, YYYY") }
                                </div>
                            </div>
                            <ResourceDownloadButton assetId={ resource.asset.id } />
                        </div>
                    ))
                }
            </div>
            <div className="flex flex-end mt-50">
                <PaginationLinks data={ resources } onPaginate={ onPaginate }/>
            </div>
        </>
    )
}

export { ResourcesList }