import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { TextInput, Button } from '@components/form'

import { login } from '@actions/user.actions'
import './login.scss'

const Login = () => {
    const [form, setForm] = useState({ email: '', password: '' })
    const [ error, setError ] = useState('')
    const [ loading, setLoading ] = useState(false)

    const handTextChange = ({ name, value }) => {
        if (!loading) {
            setForm(prev => {
                const newForm = { ...prev }
                newForm[name] = value
    
                return newForm
            })
    
            setError('')
        }
    }
    const handleLogin = () => {
        setLoading(true)
        login(form)
            .catch(() => setError('Invalid email or password'))
            .finally(() => setLoading(false))
    }

    return (
        <div
            id="loginPage"
            className="flex-1 flex direction-column"
            style={{ backgroundImage: `url("https://picsum.photos/1200/800")`}}
        >
            <div className="login-wrapper flex-1 of-auto flex align-center">
                <div className="login-scroll-control">
                    <div className="login-box bg-fff">
                        <div className="txt-bold txt-header-2">
                            LOGIN TO YOUR ACCOUNT
                        </div>
                        <TextInput
                            name="email"
                            label="Email or Phone"
                            onChange={ handTextChange }
                            value={ form.email }
                            containerClass="mt-40"
                            onSubmit={ handleLogin }
                        />
                        <TextInput
                            name="password"
                            label="Password"
                            onChange={ handTextChange }
                            value={ form.password }
                            containerClass="mt-40"
                            type="password"
                            onSubmit={ handleLogin }
                        />
                        {
                            error ? (
                                <div className="txt-error txt-bold mt-10">
                                    { error }
                                </div>
                            ) : null
                        }
                        <div className="flex mt-40">
                            <Button
                                className="flex-1 d-block txt-xlarge"
                                styles={{ width: "100%" }}
                                onClick={ handleLogin }
                                disabled={ loading }
                            >
                                LOGIN
                            </Button>
                        </div>
                        <div className="mt-10 txt-large txt-bold txt-center">
                            <Link
                                to="/login/forgot-password"
                            >
                                Forgot Password?
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login