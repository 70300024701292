import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import './layout_side_menu.scss'
import menuList from './menu_list'

const LayoutSideMenu = () => {
    const { brand } = useSelector(state => state.user)
    const location = useLocation()

    const [showMenu, setShowMenu] = useState(true)

    const onShowMenu = () => {
        setShowMenu(!showMenu)
    }

    return (
        <>
        <div id="layoutSideMenu" 
            className={`pos-fixed of-auto ${ !showMenu ? 'show-menu':'hide-menu'}`} 
            style={{ background: brand.background_color ? brand.background_color : "#231F20" }}
        >
            <div className="py-10">
                {  !showMenu &&
                    <div
                        className={`menu-link px-35 py-15 pos-relative of-hidden my-10`}
                        onClick={ () => onShowMenu() }
                    >
                        <div className="txt-large flex align-center pos-relative">
                            <i
                                className={`far fa-bars txt-large mr-20`}
                                style={{ color: "#fff" }}
                            />
                        </div>
                    </div>
                }
                {
                    menuList.map((menu, index) => (
                        <NavLink
                            key={`sidemenu${ index }`}
                            to={ menu.path }
                            exact={ menu.exact ? true : false }
                            className={`menu-link px-35 py-15 pos-relative of-hidden my-10`}
                        >
                            <div
                                className="active-bg-color pos-absolute"
                                style={{ background: brand.primary_color ? brand.primary_color : "#ED1A3A" }}
                            >
                                <div
                                    style={{ background: brand.secondary_color ? brand.secondary_color : "#00000066" }}
                                />
                            </div>
                            <div className="txt-large flex align-center pos-relative">
                                <i
                                    className={`fas fa-${ menu.icon } txt-large mr-20`}
                                    style={{ color: location.pathname === menu.path ? (brand.secondary_color ? brand.secondary_color : "#00000066") : "#fff" }}
                                />
                                <span 
                                    className="txt-secondary-bold"
                                    style={{ color: location.pathname === menu.path ? (brand.secondary_color ? brand.secondary_color : brand.secondary_color) : "#fff" }}
                                >
                                    { menu.label }
                                </span>
                            </div>
                        </NavLink>
                    ))
                }
            </div>
        </div>
        <div id="layoutSideMenuMobile" 
            className={`pos-fixed of-auto ${ showMenu ? 'show-menu':'hide-menu'}`}
            style={{ background: brand.background_color ? brand.background_color : "#231F20" }}
        >
            <div className="py-10">
                <div
                    className={`menu-link px-35 py-15 pos-relative of-hidden my-10`}
                    onClick={ () => onShowMenu() }
                >
                    <div className="txt-large flex align-center pos-relative">
                        <i
                            className={`far fa-bars txt-large mr-20`}
                            style={{ color: "#fff" }}
                        />
                    </div>
                </div>
                {
                    menuList.map((menu, index) => (
                        <NavLink
                            key={`sidemenu${ index }`}
                            to={ menu.path }
                            exact={ menu.exact ? true : false }
                            className={`menu-link px-35 py-15 pos-relative of-hidden my-10`}
                        >
                            <div
                                className="active-bg-color pos-absolute"
                                style={{ background: brand.primary_color ? brand.primary_color : "#ED1A3A" }}
                            >
                                <div
                                    style={{ background: brand.secondary_color ? brand.secondary_color : "#00000066" }}
                                />
                            </div>
                            <div className="txt-large flex align-center pos-relative">
                                <i
                                    className={`fas fa-${ menu.icon } txt-large mr-20`}
                                    style={{ color: location.pathname === menu.path ? (brand.secondary_color ? brand.secondary_color : "#00000066") : "#fff" }}
                                />
                                <span className="txt-secondary-bold opacity-0">
                                    { menu.label }
                                </span>
                            </div>
                        </NavLink>
                    ))
                }
            </div>
        </div>
        </>
    )
}

export { LayoutSideMenu }