import React from 'react'

const answerMap = ['A', 'B', 'C', 'D']
const ExamCard = ({ qa, currentQuestion, selectedAnswer, onSelectAnswer }) => {
    const choices = [
        qa[`choice_1`],
        qa[`choice_2`],
        qa[`choice_3`],
        qa[`choice_4`]
    ]
    return (
        <div className="mt-45">
            <div className="txt-bold txt-xlarge">
                POST EXAMINATION
            </div>
            <div className="exam-card mt-25 p-35">
                <div className="exam-qa-wrapper">
                    <div className="txt-header-3 txt-bold">
                        Question { currentQuestion + 1 }
                    </div>
                    <div className="txt-bold-semi txt-xlarge mt-5">
                        { qa.question }
                    </div>
                    <div className="mt-25 mt-5 grid grid-2 grid-gap-30">
                        {
                            choices.filter(answer => answer).map((answer, index) => (
                                <div
                                    key={`question${ index }`}
                                    className={`exam-a-card flex px-30 py-20 txt-bold txt-xlarge txt-20 ${ selectedAnswer === (index + 1) ? 'active' : '' }`}
                                    role="button"
                                    onClick={ () => onSelectAnswer({ question: currentQuestion, answer: index + 1 }) }
                                >
                                    <div>
                                        {answerMap[index]}.
                                    </div>
                                    <div dangerouslySetInnerHTML={{ __html: answer }}/>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ExamCard }