import React from 'react'

import achievementTableDefault from '@constants/achievement_table.map'

const table = [
    ...achievementTableDefault,
    {
        label: "NO. OF ATTEMPTS ON EXAM",
        value: (course) => {
            const attempts = course.exam_sessions.length

            return (
                <div>
                    {  attempts } Attempt{ attempts > 1 ? 's' : '' }
                </div>
            )
        }
    }
]

export default table