import React, { useContext } from 'react'
import CourseContext from '@contexts/course.context'
const AboutCourse = () => {
    const { course } = useContext(CourseContext)
    return course.learnings || course.learning_items.length ? (
        <div className="mt-50">
            <div className="pb-50" >
                <div className="txt-xxlarge txt-bold">
                    About this Course
                </div>
                <div className="txt-xlarge mt-15" dangerouslySetInnerHTML={{ __html: course.description }} />
            </div>
            <div className="p-30"  style={{ border: "1px solid #70707044" }}>
                <div className="txt-xxlarge txt-22 txt-bold">
                    What You'll Learn
                </div>
                <div className="txt-large txt-bold-semi mt-5" dangerouslySetInnerHTML={{ __html: course.learnings }} />
                {
                    course.learning_items.map((learning) => (
                        <div 
                            key={`resourceabout${ learning.id }`}
                            className="flex mt-25"
                        >
                            <i className="fas fa-check-circle txt-xlarge txt-error" />
                            <div className="ml-15 txt-xlarge" style={{ lineHeight: 1 }}>
                                { learning.content }
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    ) : null
}

export { AboutCourse }