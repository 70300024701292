import { LOGIN, LOGOUT } from '@actionTypes'
import store from '@~'
import { connect } from '@helpers/api.helper'

export function login(creds, cancelToken) {
    return store.dispatch({
        type: LOGIN,
        payload: connect().get('/examinees/auth', { params: creds, cancelToken: (cancelToken ? cancelToken.token : null) })
    })
}

export function getUser(examineId, cancelToken) {
    return store.dispatch({
        type: LOGIN,
        payload: connect().get(`/examinees/${ examineId }`, { cancelToken: (cancelToken ? cancelToken.token : null) })
    })
}

export function logout() {
    return store.dispatch({ type: LOGOUT })
}

export function update(data, id) {
    return store.dispatch({
        type: LOGIN,
        payload: connect().post(`/examinee/update/${ id }`, data)
    })
}

export function passwordReset(params) {
    return connect().post('/examinees/password-reset', params);
}