import React, { useContext } from 'react'

import { ResourceDownloadButton } from '@components/buttons'
import wordIcon from '@assets/images/icons/word-icon.svg'
import pdfIcon from '@assets/images/icons/pdf-icon.svg'
import fileIcon from '@assets/images/icons/file.png'

import CourseContext from '@contexts/course.context'
const CourseResources = () => {
    const { course } = useContext(CourseContext)

    return course.asset_groups && course.asset_groups.length ? (
        <div className="mt-40 pb-50" style={{ borderBottom: "2px solid #70707044" }}>
            <div className="txt-bold txt-xxlarge">
                Downloadable Resources
            </div>
            <div className="mt-35">
                {
                    course.asset_groups[0].assets.map((item) => (
                        <div
                            key={`courseres${ item.id }-${ item.asset.id }`}
                            className="course-resource flex align-center p-10"
                        >
                            <div className="resource-icon flex flex-end">
                                <img alt="" src={ item.asset.type === "document" ? wordIcon : (item.asset.type === "pdf" ? pdfIcon : fileIcon) }/>
                            </div>
                            <div className="txt-large txt-large-2 txt-bold-semi flex-1 px-20 break-word">
                                { item.asset.name }
                            </div>
                            <ResourceDownloadButton assetId={ item.asset.id } />
                        </div>
                    ))
                }
            </div>
        </div>
    ) : null
}

export { CourseResources }