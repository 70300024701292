import { ASSET_URL } from '@constants/config'

export function extractAssetObj(obj, custom_path = false, type = "path", customPlaceholder=false) {
    if (custom_path && custom_path.length > 0) {
        for (let i = 0; i < custom_path.length; i++) {
            let prop = custom_path[i];
            if(!obj || !obj.hasOwnProperty(prop)) {
                return customPlaceholder ? customPlaceholder : '';
            } else {
                obj = obj[prop];
            }
        }
        if (!obj) {
            return customPlaceholder ? customPlaceholder : '';
        }
        return `${ASSET_URL}${obj}`;
    }
    
    if (obj && obj.asset && obj.asset[type]) {
        return `${ASSET_URL}${obj.asset[type]}`;
    } else if (obj && obj[type]) {
        return `${ASSET_URL}${obj[type]}`;
    }

    return customPlaceholder ? customPlaceholder : '';
}