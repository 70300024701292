import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import Routes from './router'
import reportWebVitals from './reportWebVitals'
import storeConf from '@store'
import { PersistGate } from 'redux-persist/lib/integration/react';

import './assets/fontawesome/fontawesome.css'
import './assets/scss/app.scss'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const { store, persistor } = storeConf()
ReactDOM.render(
  <React.StrictMode>
    <PersistGate loading={null} persistor={persistor}>
      <Provider store={ store }>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>  
      </Provider>
    </PersistGate>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
export default store
