import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { extractAssetObj } from '@helpers/image.helper'

const UserImage = ({ containerClass, className }) => {
    const { user } = useSelector(state => state)
    const [ withError, setWithError ] = useState(false)

    return (
        <div className={`pos-relative ${ containerClass }`}>
            <img
                alt=""
                src={ user.image ? extractAssetObj( user ) : '' }
                className={ className }
                style={{ backgroundColor: "#ccc" }}
                onError={ () => setWithError(true) }
            />
            {
                withError ? (
                    <div
                        className="pos-absolute flex align-center justify-center txt-bold"
                        style={{
                            borderRadius: 100,
                            width: "100%",
                            height: "100%",
                            left: 0,
                            top: 0,
                            color: "#fff",
                            fontSize: "2vw",
                            background: user.brand.primary_color ? user.brand.primary_color : "#ccc"
                        }}
                    >
                        { user.first_name[0] }
                    </div>
                ) : null
            }
        </div>
    )
}

export default UserImage