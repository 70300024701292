import Courses from '@pages/courses'
import Course from '@pages/course'
import Exam from '@pages/course/exam'

const coursesRoute = [
    {
        routeProps: {
            key: "courses",
            exact: true,
            path: '/courses'
        },
        component: <Courses />
    },
    {
        routeProps: {
            key: "course",
            exact: true,
            path: '/courses/:id'
        },
        component: <Course />
    },
    {
        routeProps: {
            key: "course-exam",
            exact: true,
            path: '/courses/:id/exam'
        },
        component: <Exam />
    }
]

export default coursesRoute