const sort = [
    {
        label: "Alphabetical",
        value: 'alpha'
    },
    {
        label: "Latest - Oldest Upload",
        value: 'l-h'
    },
    {
        label: "Oldest - Latest Upload",
        value: 'h-l'
    }
]

export default sort