import React, { useRef, useState, useEffect } from 'react'

const Accordion = ({ children, collapse = true }) => {
    const collapseWrapper = useRef();
    const heightControlWrapper = useRef();
    const [maxHeight, setMaxHeight] = useState(0);

    useEffect(() => {
        setMaxHeight(heightControlWrapper.current.clientHeight)
    }, [])

    return (
        <div
            ref={ collapseWrapper }
            className="of-hidden"
            style={{
                transition: `0.3s`,
                maxHeight: collapse ? 0 : maxHeight
            }}
        >
            <div ref={ heightControlWrapper }>
                { children }
            </div>
        </div>
    )
}

export default Accordion