import Resources from '@pages/resources'

const resourcesRoutes = {
    routeProps: {
        key: "resources",
        exact: true,
        path: '/resources'
    },
    component: <Resources />
}

export default resourcesRoutes