import React, { useState, useEffect } from 'react'

const TextInput = ({
    name,
    label,
    onChange,
    onSubmit,
    value,
    type = "text",
    containerClass
}) => {
    const [active, setActive] = useState(false)
    const [isMasked, setMask] = useState(true)

    useEffect(() => {
        if (value) setActive(true)
    }, [value]);

    return (
        <div
            className={`form-input flex pos-relative ${ active ? "active" : '' } ${ containerClass }`}
            style={{
                borderColor: !active ? "#70707070" : "#ED1A3A"
            }}
        >
            <input
                type={ (type !== "password" && type !== "text") || !isMasked ? "text" : type }
                className="flex-1"
                name={ name }
                value={ value.toString() ? value : '' }
                onChange={ e => onChange(e.target) }
                onFocus={ () => setActive(true) }
                onBlur={ () => value ? {} : setActive(false) }
                onKeyPress={ (e) => {
                    if(e.key === "Enter" && onSubmit) onSubmit()
                }}
            />
            {
                type === "password" ? (
                    <button
                        className="form-input-button btn-icon"
                        onClick={ () => setMask(prev => !prev) }
                    >
                        <i className={`fas fa-eye${ isMasked ? '' : '-slash'} txt-large icon txt-gray-med`} />
                    </button>
                ) : null
            }
            <div
                className="form-input-label pos-absolute"
                style={{ color: !active ? "#B0B8C5" : "#ED1A3A" }}
            >
                { label }
            </div>
        </div>
    )
}

export { TextInput }