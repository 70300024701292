import React from 'react'

import { Checkbox } from '@components/form'
import filters from './resource_filters.map'
const ResourceFilters = ({ activeFilter, activeOtherFilter, onFilterChange, onOtherFilterChange }) => {

    return (
        <div className="resource-config-box">
            <div className="txt-large txt-bold txt-white py-15 px-20 bg-black">
                FILTER BY:
            </div>
            <div className="p-20">
                <div className="txt-medium-large txt-919191 txt-bold mb-15">
                    TYPE:
                </div>
                <div className="config-group">
                    {
                        filters.type.map((filter, index) => (
                            <Checkbox
                                key={`filtertype${ index }`}
                                containerClass="mt-15"
                                label={ filter.label }
                                onChange={ () => onFilterChange( filter.value ) }
                                isChecked={ activeFilter === filter.value }
                            />
                        ))
                    }
                </div>
                <div className="txt-medium-large txt-919191 txt-bold mb-15 mt-35">
                    OTHERS:
                </div>
                <div className="config-group">
                    {
                        filters.other.map((filter, index) => (
                            <Checkbox
                            key={`filterother${ index }`}
                                containerClass="mt-15"
                                label={ filter.label }
                                onChange={ () => onOtherFilterChange( filter.value ) }
                                isChecked={ activeOtherFilter === filter.value }
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export { ResourceFilters }