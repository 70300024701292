import React from 'react'
import { withRouter, Router, Route, Switch, useHistory } from 'react-router-dom'
import Layout from '@components/layout' //-- HOC

import routes from './routes'

const Routes = () => {
    const history = useHistory()

    return (
        <Layout>
            <Router history={ history }>
                <Switch>
                    {
                        routes.map(route => (
                            <Route { ...route.routeProps }>
                                { route.component }
                            </Route>
                        ))
                    }
                </Switch>
            </Router>
        </Layout>
    )
}

export default withRouter(Routes)

