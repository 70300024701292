import React from 'react'

import achievementTableDefault from '@constants/achievement_table.map'
import pdfIcon from '@assets/images/icons/pdf-icon.svg'

const table = [
    ...achievementTableDefault,
    {
        label: "CERTIFICATE OF COMPLETION",
        value: (course) => (
            <div className="flex align-center table-cert txt-error">
                <div>
                    <img
                        alt=""
                        src={ pdfIcon }
                    />
                </div>
                <div className="ml-10 txt-ellipsis txt-nowrap of-hidden">
                    <span className="txt-medium">
                        Certificate of Completion for the course { course.name } 
                    </span>
                </div>
            </div>
        )
    }
]

export default table