import * as types from '@actionTypes';
import initialState from './initialState';

export default function userReducer (state = initialState.user, action) {

    switch(action.type) {
        case types.LOGOUT:
        case types.LOGIN + types.REJECTED:
            return {};
        case types.LOGIN + types.FULFILLED:
            
            return Object.assign({},
                state,
                action.payload.data,
                {
                    loading: false
                }
            );
        default: 
            return state;
    }
}