import React from 'react'
import * as config from '@constants/config'
const ResourceDownloadButton = ({ assetId }) => {
    return (
        <a
            className="btn btn-icon py-15 px-20"
            style={{ background: "#D6D6D6" }}
            href={`${config.ASSET_URL}resources/download/${ assetId }`}
        >
            <i className="fas fa-download icon txt-error" />
        </a>
    )
}

export { ResourceDownloadButton }