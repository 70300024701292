import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Index = () => {
    const { user } = useSelector(state => state)
    const history = useHistory()

    useEffect(() => {
        if (user && user.id) history.replace('/dashboard')
        else history.replace('/login')
    }, [history, user])

    return <div></div>
}

export default Index