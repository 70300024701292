import Reports from '@pages/account/reports'
import Settings from '@pages/account/settings'
import Profile from '@pages/account/settings/profile'
import CookiePolicy from '@pages/account/settings/cookie_policy'

const accountRoute = [
    {
        routeProps: {
            key: "account-reports",
            exact: true,
            path: '/account/reports'
        },
        component: <Reports />
    },
    {
        routeProps: {
            key: "account-settings",
            exact: true,
            path: '/account/settings'
        },
        component: <Settings />
    },
    {
        routeProps: {
            key: "account-settings-profile",
            exact: true,
            path: '/account/settings/profile'
        },
        component: <Profile />
    },
    {
        routeProps: {
            key: "account-settings-cookie-policy",
            exact: true,
            path: '/account/settings/cookie-policy'
        },
        component: <CookiePolicy />
    }
]

export default accountRoute