import React from 'react'

import { CourseCard } from './components'
import PaginationLinks from '@components/pagination_links'
import CourseFactory from '@factories/course.factory'
const CourseList = ({ courses, onSetParams }) => {

    return (
        <div>
            <div className="pb-10">
                {
                    courses && courses.data && courses.total ? (
                        courses.data.map( course => <CourseCard key={`course${ course.id }`} course={ new CourseFactory(course) } /> )
                    ) : (
                        <div className="txt-center txt-large txt-bold txt-error pad-30 mt-50">
                            No course found.
                        </div>
                    )
                }
            </div>
            <PaginationLinks
                data={ courses }
                onPaginate={ newPage => onSetParams(prev => {
                    const newParams = { ...prev }
                    newParams.page = newPage

                    return newParams
                })}
            />
        </div>
    )
}

export { CourseList }