const filters = {
    type: [
        {
            label: "All Types",
            value: ''
        },
        {
            label: "PDFs/Portable Document Format",
            value: 'pdf'
        },
        {
            label: "Docx/Microsoft Word Format",
            value: 'document'
        },
        {
            label: "Other Types",
            value: 'other'
        }
    ],
    other: [
        {
            label: "All Available Resources",
            value: ''
        },
        {
            label: "Ongoing Courses Resources",
            value: 'ongoing'
        }
    ]
}

export default filters