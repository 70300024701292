import React from 'react'

import { Checkbox } from '@components/form'
import sort from './resource_sort.map'
const ResourcesSorting = ({ activeSort, onSortChange }) => {

    return (
        <div className="resource-config-box mt-20">
            <div className="txt-large txt-bold txt-white py-15 px-20 bg-black">
                SORT BY:
            </div>
            <div className="p-20">
                <div>
                    {
                        sort.map((option, index) => (
                            <Checkbox
                                key={`sortby${ index }`}
                                containerClass="mt-15"
                                label={ option.label }
                                onChange={ () => onSortChange( option.value ) }
                                isChecked={ activeSort === option.value }
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export { ResourcesSorting }