import React, { useState } from 'react'

import Accordion from '@components/accordion'

const FAQGroup = ({ faq }) => {
    const [ collapse, setCollapse ] = useState(true)

    return (
        <div
            role="button"
            onClick={ () => setCollapse(prev => !prev) }
        >
            <div className="faq-question py-30 txt-xlarge flex space-between align-center">
                <div className="txt-bold">
                    { faq.question }
                </div>
                <i
                    className="far fa-chevron-down"
                    style={{
                        transition: `0.2s`,
                        transform: `rotate(${ collapse ? 0 : 180 }deg)`
                    }}
                />
            </div>
            <Accordion collapse={ collapse }>
                <div
                    className="faq-answer px-30 py-10 txt-large"
                    dangerouslySetInnerHTML={{ __html: faq.answer }}
                />
            </Accordion>
        </div>
    )
}

export { FAQGroup }