import React, { useState, useEffect } from 'react'

const Checkbox = ({ onChange, isChecked, containerClass, label }) => {
    const [ checked, setChecked ] = useState(false)
    const handleCheck = (value) => {
        setChecked(value)
        if (onChange) onChange(value)
    }
    useEffect(() => {
        if (isChecked !== checked) setChecked(isChecked)
    }, [isChecked, checked])

    return (
        <div className={`flex ${ containerClass } `}>
            <div
                className="lms-checkbox flex align-center"
                role="button"
                onClick={ () => handleCheck(!checked) }
            >
                <div className={`box ${ checked ? "is-checked" : '' }`}>
                    <i className="far fa-check txt-white flex align-center justify-center" />
                </div>
                {
                    label ? (
                        <div className="flex-1 ml-15 txt-large txt-bold-semi">
                            { label }
                        </div>
                    ) : null
                }
            </div>
        </div>
    )
}

export { Checkbox }