import React from 'react'
import { extractAssetObj } from '@helpers/image.helper'

const HeadBanner = ({ faqs }) => {

    return (
        <div
            className="faqs-banner txt-center txt-bold txt-white"
            style={{ backgroundImage: `url(${ extractAssetObj(faqs.content.background_image, ["path"]) })` }}
        >
            <div className="flex justify-center">
                <div>
                    <div>
                        { faqs.content.title }
                    </div>
                    <div className="faqs-underline" />
                </div>
            </div>
        </div>
    )
}

export { HeadBanner }