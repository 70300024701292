import React from 'react'

const Sekeleton = ({ count = 1, marginTop = 0, randomWidth = false, boneStyles = {} }) => {

    return (
        <div className="skeleton-loader grid grid-gap-20" style={{ marginTop }}>
            { [...Array(count)].map((bone, index) => ( <div key={`skeletonbond-${ index }`} className="skeleton-bone" style={ boneStyles } /> )) }
        </div>
    )
}

export default Sekeleton