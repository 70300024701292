import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'

import UserImage from '@components/user_image'
import { DropdownMenu } from './components'

const UserDropDown = () => {
    const wrapperRef = useRef()
    const { user } = useSelector(state => state)
    const [ collapse, setCollapse ] = useState(false)
    
    const handleClickOutside = (event) => {
        if ( wrapperRef.current && !wrapperRef.current.contains(event.target) ) setCollapse(false)
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    })

    return (
        <div className="pos-relative mr-50" ref={ wrapperRef } >
            <div className="flex align-center" role="button" onClick={ () => setCollapse(prev => !prev)}>
                <div>
                    <UserImage
                        className="img img-round img-thumb-medium img-sqr img-cover"
                    />
                </div>
                <div className="ml-20 user-name">
                    <div className="txt-error txt-large txt-bold">
                        { user.first_name }
                    </div>
                    <div className="txt-large txt-secondary-regular">
                        { user.email }
                    </div>
                </div>
                <i className="fas fa-caret-down txt-large ml-25" />
            </div>
            <DropdownMenu collapse={ collapse } onSetCollapse={ setCollapse } />
        </div>
    )
}

export { UserDropDown }