import React, { useEffect, useState } from 'react'
import { connect } from '@helpers/api.helper'

import { HeadBanner, FAQGroup, FAQsLoader } from './components'
import './faqs.scss'

const FAQs = () => {
    const [ loading, setLoading ] = useState(true)
    const [ faqs, setFaqs ] = useState(null)

    useEffect(() => {
        connect().get('/page/faqs')
            .then(res => {
                if (res.data) setFaqs(res.data)
            })
            .finally(() => setLoading(false))
    }, [])

    return (
        <div id="faqsPage">
            <div className="faqs-box" >
                {
                    loading ? (
                        <FAQsLoader />
                    ) : (
                        faqs ? (
                            <>
                                <HeadBanner faqs={ faqs } />
                                <div className="faqs-list px-30 pt-10 pb-50">
                                    {
                                        faqs.content.items && faqs.content.items.map((faq, index) => (
                                            <FAQGroup
                                                key={`faq-${ index }`}
                                                faq={ faq }
                                            />
                                        ))
                                    }
                                </div>
                            </>
                        ) : (
                            <div className="txt-error txt-bold txt-large txt-center pad-30 mt-50">
                                Sorry, but it seemst that this page is under construction.
                            </div>
                        )
                    )
                }
            </div>
        </div>
    )
}

export default FAQs