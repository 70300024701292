import React from 'react'

const ExamProgress = ({ qas, currentQuestion }) => {

    return (
        <div className="flex align-center mt-35">
            <div className="exam-progress-bar flex-1 position-relative">
                <div
                    className="position-absolute"
                    style={{
                        maxWidth: `${ ((currentQuestion + 1) / qas.length) * 100 }%`
                    }}
                />
            </div>
            <div className="exam-progress-count txt-bold txt-right">
                { currentQuestion + 1 } / { qas.length }
            </div>
        </div>
    )
}

export { ExamProgress }