import React from 'react'

import Skeleton from '@components/skeleton_loader'

const FAQsLoader = () => {

    return (
        <div>
            <Skeleton
                boneStyles={{ height: 200 }}
            />
            <Skeleton
                marginTop={ 20 }
                boneStyles={{ height: 150 }}
                count={ 5 }
            />
        </div>
    )
}

export { FAQsLoader }