import React from 'react'
import { useSelector } from 'react-redux'

import { SearchInput, UserDropDown } from './components'
import { extractAssetObj } from '@helpers/image.helper'
import brandLogo from '@assets/images/placeholders/brand_logo.png'
import './layout_nav.scss'

const LayoutNav = () => {
    const { brand } = useSelector(state => state.user)

    return (
        <div id="layoutNav" className="flex align-center pos-sticky">
            <div className="brand-logo py-15">
                <img
                    src={ brand ? extractAssetObj(brand, ["logo", "path"]) : brandLogo }
                    alt="brand logo"
                    className="img img-cover mx-auto"
                />
            </div>
            <SearchInput />
            <UserDropDown />
        </div>
    )
}

export { LayoutNav }