import Achievements from '@pages/achievements'

const achievementsRoutes = {
    routeProps: {
        key: "achievements",
        exact: true,
        path: '/achievements'
    },
    component: <Achievements />
}

export default achievementsRoutes