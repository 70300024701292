const menuList = [
    {
        label: "Dashboard",
        path: "/dashboard",
        exact: true,
        icon: "th-large"
    },
    {
        label: "All Courses",
        path: "/courses",
        icon: "graduation-cap"
    },
    {
        label: "Achievements",
        path: "/achievements",
        icon: "trophy"
    },
    {
        label: "Resources",
        path: "/resources",
        icon: "books"
    },
    {
        label: "FAQs",
        path: "/faqs",
        icon: "info-circle"
    }
];

export default menuList;