const links = [
    {
        label: "Profile",
        icon: "fas fa-user",
        to: "/account/settings/profile"
    },
    {
        label: "Reports",
        icon: "fas fa-file-alt",
        to: "/account/reports"
    },
    {
        label: "Settings",
        icon: "fas fa-cog",
        to: "/account/settings"
    }
]

export default links