import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Button } from '@components/form'

import CourseContext from '@contexts/course.context'
import { startExamSession } from '@actions/course.actions'
import { extractAssetObj } from '@helpers/image.helper'
import * as config from '@constants/config'

import ReactAudioPlayer from 'react-audio-player';
// import Slider from 'react-slick';
import { Carousel } from 'react-responsive-carousel';

const CourseActiveModule = () => {
    const history = useHistory()
    const { user } = useSelector(state => state)
    const { course, modules, activeModule, toggleLoading } = useContext(CourseContext)
    const handleStartExam = () => {
        toggleLoading(true)
        startExamSession({
            course_id: course.id,
            examinee_id: user.id
        }).then(res => {
            if (res && res.data) {
                sessionStorage.setItem(`exam`, res.data.session_token)
                history.push(`${ history.location.pathname }/exam`)
            }
        })
        .finally(() => toggleLoading(false))
    }

    let settings = {
        dynamicHeight: true,
        autoPlay: false
    };

    console.log(modules)
    
    const isBlocked = course.exam_attempts && course.attempt_limit && (course.exam_attempts.length >= +course.attempt_limit)
    return (
        <div className={`course-active-module ${ modules[activeModule].type == 'audio' || modules[activeModule].type == 'powerpoint' ? "audio-active-module": (modules[activeModule].type == 'video' ? "": 'images-active-module') }`}>
            {
                modules.length === (activeModule + 1) ? (
                    <div>
                        {
                            !course.examinee_done_session ? (
                                isBlocked ? (
                                    <div>
                                        <div className="post-exam-module p-30">
                                            <div className="txt-bold txt-header-2 txt-30">
                                                Post Examination
                                            </div>
                                            <div className="txt-xlarge mt-5">
                                                It looks like you've reached the maxmimum limit of attempt for this course.
                                            </div>
                                            <div className="txt-xlarge mt-5">
                                                The brand admin will contact you once you have been given access for this course again.
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="post-exam-module p-30">
                                            <div className="txt-bold txt-header-2 txt-30">
                                                Post Examination
                                            </div>
                                            <div className="txt-xlarge mt-5">
                                                Congratulations! You have completed watching all the videos for this course.
                                                <br/>
                                                For the last part, you may receive your certificate of completion after passing this last examination first with { +course.passing_percentage / 1 }% correct marks. { course.attempt_limit ? `You can retake the exam up to ${ course.attempt_limit } time/s` : `You may attempt to re-answer this part as many times you need to succeed in passing` }.
                                            </div>
                                            <div className="txt-xlarge mt-40">
                                                This exam has <span className="txt-bold">{ modules[activeModule].duration } ITEM{ +modules[activeModule].duration > 1 ? 'S' : '' }.</span>
                                            </div>
                                        </div>
                                        {
                                            +modules[activeModule].duration ? (
                                                <Button
                                                    className="btn btn-error mt-45 txt-medium txt-16"
                                                    onClick={ handleStartExam }
                                                >
                                                    START EXAM
                                                </Button>
                                            ) : null
                                        }
                                    </>   
                                )
                            ) : (
                                <div className="post-exam-module p-30">
                                    <div className="txt-bold txt-header-2 txt-30">
                                        Congratulations!
                                    </div>
                                    <div className="txt-xlarge mt-5">
                                        You Passed the Examination.
                                        <br />
                                        You may now download your certificate of completion.
                                    </div>
                                    <a
                                        className="btn btn-red flex-1 txt-bold mt-35 txt-medium txt-16"
                                        href={`${config.ASSET_URL}download/${ user.id }/certificate/${ course.id }`}
                                        style={{ display: "inline-block" }}
                                    >
                                        DOWNLOAD
                                    </a>
                                </div>
                            )
                        }
                    </div>
                ) : (
                    modules[activeModule].type != 'video' && modules[activeModule].type != "" ? (
                        modules[activeModule].type == 'audio' ? (
                            modules[activeModule].audio ? (
                                <div className="course-active-module audio-active-module ">
                                    <div className="audio-grid">
                                        <ReactAudioPlayer
                                            src={`${config.ASSET_URL}${modules[activeModule].audio.path}`}
                                            className="audio-player"
                                            controls
                                        />
                                        <a
                                            className="btn btn-icon py-15 px-20"
                                            style={{ background: "#D6D6D6" }}
                                            href={`${config.ASSET_URL}resources/download/${ modules[activeModule].audio.id }`}
                                        >
                                            Download Audio
                                        </a>
                                    </div>
                                    <div className="module-overlay flex align-center justify-center" >
                                        <i  className="fas fa-play-circle txt-white txt-header" />
                                    </div>
                                </div>
                            ) : (
                                <div className="pad-30 txt-large txt-bold">
                                    { modules[activeModule].name }
                                </div>
                            )
                        ):(
                            modules[activeModule].type == 'powerpoint' ? (
                                modules[activeModule].powerpoint ? (
                                    <div className="course-active-module audio-active-module">
                                        <div className="">
                                            <a
                                                className="btn btn-icon py-15 px-20"
                                                style={{ background: "#D6D6D6" }}
                                                href={`${config.ASSET_URL}resources/download/${ modules[activeModule].powerpoint.id }`}
                                            >
                                                Download PowerPoint Slides
                                            </a>
                                        </div>
                                        <div className="module-overlay flex align-center justify-center" >
                                            <i  className="fas fa-play-circle txt-white txt-header" />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="pad-30 txt-large txt-bold">
                                        { modules[activeModule].name }
                                    </div>
                                )
                            ):(
                                modules[activeModule].asset_groups && modules[activeModule].asset_groups.length > 0 ? (
                                    <div className="course-active-module images-active-module">
                                        <div className="slider-container">
                                            {/* <Slider {...settings}>
                                                {
                                                    modules[activeModule].asset_groups[0].assets.map((item, key) => (
                                                        <div key={key}>
                                                            <img 
                                                                src={ item.asset.type == 'image' ? extractAssetObj( item.asset ) : '' }
                                                            />
                                                        </div>
                                                    ))
                                                }
                                            </Slider> */}
                                            <Carousel {...settings}>
                                                {
                                                    modules[activeModule].asset_groups[0].assets.map((item, key) => (
                                                        <div key={key}>
                                                            <img 
                                                                src={ item.asset.type == 'image' ? extractAssetObj( item.asset ) : '' }
                                                                className="slick-slide"
                                                            />
                                                        </div>
                                                    ))
                                                }
                                            </Carousel>
                                        </div>
                                        <div className="module-overlay flex align-center justify-center" >
                                            <i  className="fas fa-play-circle txt-white txt-header" />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="pad-30 txt-large txt-bold">
                                        { modules[activeModule].name }
                                    </div>
                                )
                            )
                        )
                    ):(
                        modules[activeModule].video_tag && modules[activeModule].video_tag.frametag ? (
                            <div className="course-active-module">
                                <div className="module-video-wrapper pos-absolute">
                                    <div
                                        dangerouslySetInnerHTML={{ __html: modules[activeModule].video_tag.frametag }}
                                    />
                                </div>
                                <div className="module-overlay flex align-center justify-center" >
                                    <i  className="fas fa-play-circle txt-white txt-header" />
                                </div>
                            </div>
                        ) : (
                            <div className="pad-30 txt-large txt-bold">
                                { modules[activeModule].name }
                            </div>
                        )
                    )
                )
            }
        </div>
    )
}


export { CourseActiveModule }