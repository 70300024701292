const passwordFields = [
    {
        grid: true,
        fields: [
            {
                label: "Password",
                name: "humanized_password"
            },
            {
                label: "Confirm Password",
                name: "cpassword"
            }
        ]
    }
]

export default passwordFields