import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { findCourse, markModuleAsDone } from '@actions/course.actions'
import { CourseDetails, CourseModuleList, CourseLoader } from './components'
import './course.scss'

import CourseContext from '@contexts/course.context'
import CourseFactory from '@factories/course.factory'

const Course = () => {
    const { id } = useParams()
    const [ course, setCourse ] = useState(null)
    const [ modules, setModules ] = useState([])
    const [ activeModule, setActiveModule ] = useState(0)
    const [ isLoading, setIsLoading ] = useState(false)
    const { user } = useSelector(state => state)
    
    const handleModuleSelect = (id) => {
        // console.log(modules)
        if (modules[+id - 1] && !modules[+id - 1].done) {
            setIsLoading(true)
            markModuleAsDone({
                examinee_id: user.id,
                module_id: modules[+id - 1].id,
                course_id: course.id
            })
            .then(() => {
                setModules(prev => {
                    let newModules = [ ...prev ]
                    if (newModules[+id - 1]) newModules[+id - 1].done = true
        
                    return newModules
                })
                setActiveModule(id)
            })
            .finally(() => setIsLoading(false))
        }
        else setActiveModule(id)
    }

    useEffect(() => {
        findCourse(id, { examinee_id: user.id })
            .then(res => {
                if (res && res.data) {
                    const newCourse = new CourseFactory({ ...res.data })
                    setModules([
                        ...res.data.modules,
                        {
                            name: "Post Examination",
                            duration: `${ res.data.questions ? res.data.questions.length : 0 }`,
                            done: newCourse.examinee_done_session ? true : false
                        }
                    ])

                    setCourse(newCourse)
                }
            })
    }, [ id ])

    return course ? (
        <div id="coursePage">
            <div className="txt-header txt-bold">
                { course.name }
            </div>
            { course.short_description ? <div className="txt-large txt-bold-semi mt-10" dangerouslySetInnerHTML={{ __html: course.short_description }}/> : null }
            <CourseContext.Provider
                value={{
                    course,
                    activeModule,
                    modules,
                    onUpdateModules: setModules,
                    onModuleSelect: handleModuleSelect,
                    parseModuleDuration: course.parseModuleDuration,
                    getTotalDuration: course.getTotalDuration,
                    toggleLoading: loading => setIsLoading(loading)
                }}
            >
                <div className="grid grid-layout-wrapper mt-40 courses-inner-grid">
                    <CourseDetails/>
                    <CourseModuleList />
                </div>
            </CourseContext.Provider>
            {
                isLoading ? (
                    <div className="loading-overlay">
                        <i className="fas fa-circle-notch txt-error" />
                    </div>
                ) : null
            }
        </div>
    ) : <CourseLoader />
}

export default Course