const formRules = {
    properties: {
        first_name: {
            type: 'string',
            required: true,
            allowEmpty: false,
            message: "This field is required"
        },
        email: {
            type: 'string',
            format: "email",
            required: true,
            allowEmpty: false,
            message: "Please enter a valid email address"
        },
        contact_number: {
            type: 'string',
            required: true,
            allowEmpty: false,
            message: "This field is required"
        },
        address: {
            type: 'string',
            required: true,
            allowEmpty: false,
            message: "This field is required"
        }
    }
}

export default formRules