/* eslint-disable no-undef */
const prod = {
	APP_KEY: process.env.REACT_APP_APP_KEY,
	API_URL: process.env.REACT_APP_API_URL,
	SITE_URL: process.env.REACT_APP_SITE_URL,
	ASSET_URL: process.env.REACT_APP_ASSET_URL,
	APP_URL: process.env.REACT_APP_APP_URL
};
const dev = {
	APP_KEY: process.env.REACT_APP_DEV_APP_KEY,
	API_URL: process.env.REACT_APP_DEV_API_URL,
	SITE_URL: process.env.REACT_APP_DEV_SITE_URL,
	ASSET_URL: process.env.REACT_APP_DEV_ASSET_URL,
	APP_URL: process.env.REACT_APP_DEV_APP_URL
};

const config = process.env.NODE_ENV === 'production'
  ? prod : dev;
	
export const APP_KEY = config.APP_KEY;
export const API_URL = config.API_URL;
export const SITE_URL = config.SITE_URL;
export const ASSET_URL = config.ASSET_URL;
export const APP_URL = config.APP_URL;