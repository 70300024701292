import React from 'react'
import { Button } from '@components/form'

const ExamCTAs = ({ onChangeQuestion, lastQuestion, firstQuestion, nextEnabled, isLoading }) => {

    return (
        <div className="exam-ctas mt-45 flex">
            {
                !firstQuestion ? (
                    <button
                        className="btn btn-gray"
                        onClick={ () => onChangeQuestion("prev") }
                    >
                        PREVIOUS
                    </button>
                ) : null
            }
            <Button
                className="btn btn-error ml-10"
                onClick={ () => onChangeQuestion("next") }
                disabled={ !nextEnabled || isLoading }
            >
                { lastQuestion ? "SUBMIT EXAM" : "NEXT"}
            </Button>
        </div>
    )
}

export { ExamCTAs }