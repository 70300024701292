import React from 'react'
import {parse, stringify} from 'query-string'
import { useHistory } from 'react-router-dom'

const PaginationLinks = ({ data, onPaginate }) => {
    const history = useHistory()
    const handlePagination = (newPage) => {
        const params = parse(history.location.search)
        params.page = newPage

        if (params.page > data.last_page) params.page = data.last_page
        if (params.page <= 0 ) params.page = 1

        history.push(`${history.location.pathname}?${stringify(params)}`)
        if (onPaginate) onPaginate(newPage)
    }

    return data.last_page > 1 ? (
        <div className="pagination-links flex flex-end align-center">
            <button
                className="pl-button txt-large txt-bold-semi"
                onClick={ () => handlePagination( +data.current_page - 1 ) }
                disabled={ +data.current_page === 1 }
            >
                <span style={{ color: "#ED1A3A"}}>PREVIOUS</span>
            </button>
            {
                [...Array(data.last_page)].map((button, index) => (
                    <button
                        key={`paginationlink${button}${index}`}
                        className="pl-button txt-large txt-bold-semi ml-20"
                        onClick={ () => handlePagination( index + 1 ) }
                    >
                        <span style={{ color: "#ED1A3A"}}>{index + 1}</span>
                    </button>
                ))
            }
            <button
                className="pl-button txt-large txt-bold-semi ml-20"
                onClick={ () => handlePagination( +data.current_page + 1 ) }
                disabled={ +data.current_page === +data.last_page }
            >
                <span style={{ color: "#ED1A3A"}}>NEXT</span>
            </button>
        </div>
    ) : null
}

export default PaginationLinks