import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { LayoutBody, LayoutNav, LayoutSideMenu } from './components'
import { getUser } from '@actions/user.actions'

const Layout = ({ children }) => {
    const { user } = useSelector(state => state)
    const [ mounted, setMounted ] = useState(false)
    const history = useHistory()

    useEffect(() => {
        if ( mounted && !user.id ) history.replace('/login')
        else if (history.location.pathname === "/login") history.replace('/dashboard')
    }, [mounted, user.id])

    useEffect(() => {
        setMounted(true)
        if (user && user.id) getUser(user.id)
    }, [])

    return mounted ? (
        <div
            id="layoutWrapper"
            className="flex direction-column"
            style={{ minHeight: "100vh" }}
        >
            {
                user && user.id ? (
                    <>
                        <LayoutNav />
                        <LayoutSideMenu />
                        <LayoutBody>
                            { children }
                        </LayoutBody>
                    </>
                ) : children
            }
        </div>
    ) : null
}

export default Layout;