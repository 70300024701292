import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

import * as config from '@constants/config'

import passedIcon from '@assets/images/icons/passed.svg'
import failedIcon from '@assets/images/icons/failed.svg'

const ExamResult = ({ score = 0, passing = 0, userId, courseId }) => {
    const params = useParams()
    const result = score >= passing ? "passed" : "failed"

    useEffect(() => {
        sessionStorage.removeItem('exam')
    }, [])
    
    return (
        <div className="flex-1 flex align-center justify-center">
            <div className="exam-result-card p-50 txt-center">
                <img
                    src={ result === "passed" ? passedIcon : failedIcon }
                    alt=""
                    className="mx-auto"
                />
                <div className="mt-25 txt-xlarge txt-bold">
                    {
                        result === "passed" ? (
                            <>
                                Congratulations!
                                <br/>
                                You Passed the Examination.
                            </>
                        ) : (
                            <>
                                Your Result Is
                                    <br/>
                                Below The Passing Score
                            </>
                        )
                    }
                </div>
                <div className="mt-20 txt-large">
                    {
                        result === "passed" ? (
                            "You may now download your certificate of completion."
                        ) : (
                            <>
                                We regret to inform you that you failed the examination.
                                    <br/>
                                However you can still retake to get better outcome.
                            </>
                        )
                    }
                    
                </div>
                <div className="flex space-between mt-30">
                    <Link
                        to="/courses"
                        replace
                        className="btn btn-gray flex-1 txt-error txt-bold"
                    >
                        START NEW COURSE
                    </Link>
                    <div className="mx-10"/>
                    {
                        result === "passed" ? (
                            <a
                                className="btn btn-red flex-1 txt-bold"
                                href={`${config.ASSET_URL}download/${ userId }/certificate/${ courseId }`}
                            >
                                DOWNLOAD
                            </a>
                        ) : (
                            <Link
                                className="btn btn-red flex-1 txt-bold"
                                replace
                                to={`/courses/${ params.id }`}
                            >
                                RE-TAKE EXAM
                            </Link>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export { ExamResult }