import axios from 'axios'
import * as config from '@constants/config'
import store from '@~'

export function connect(showLog = true, additionalHeaders = {}){
    const user =store.getState().user
	let instance
	let headers = Object.assign({'Access-Control-Allow-Origin': '*'}, additionalHeaders)
	
	if (user && user.hasOwnProperty("id")) {
		headers = Object.assign({} , headers, {'Authorization': 'Bearer ' + (user.id ? user.id : '')})

		instance = axios.create({
			baseURL: config.API_URL,
			headers
		})
	} else {
		instance = axios.create({
			headers,
			baseURL: config.API_URL
		})
	}

	instance.interceptors.response.use(function (response) {
		//only return the data
		return response.data
	}, function (error) {
		const {response} = error
		
		if (response && response.status) {
            //DO SOMETHING HERE 
        }
		return Promise.reject(response)
	})
	return instance
}
