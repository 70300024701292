import React from 'react'

import Sekeleton from '@components/skeleton_loader'

const ResourcesLoader = () => {

    return (
        <div>
            <Sekeleton
                count={ 5 }
                boneStyles={{
                    height: 100
                }}
            />
        </div>
    )
}

export { ResourcesLoader }